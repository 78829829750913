import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { BulkActions } from "./bulk-actions";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const FinanceExportBillsReportConfig: ReportConfigBase = {
  type: EReportTypes.FinanceExportBills,
  endpoint: "bill/unexported-bills",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: true,
  intro: {
    description:
      "Export Bills",
    url: "https://help.commonsku.com/knowledge/supplier-billing-reports"
  },
  bulkActions: BulkActions,
};

export default FinanceExportBillsReportConfig;
