import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Invoice Date",
    type: FILTER_TYPES.DateRange,
    fields: ["category_order_date", "start_order_date", "end_order_date"],
    defaultValue: ["this_year", "", ""],
  },
  {
    label: "Client",
    type: FILTER_TYPES.Client,
    fields: "client_id",
  },
  {
    label: "Order Rep",
    type: FILTER_TYPES.User,
    fields: ["sales_rep_id", "sales_rep_team_ids", "include_inactive_sales_reps"],
  },
  {
    label: "Project",
    fields: "project_search",
    type: FILTER_TYPES.Query,
    fetchReportOnEnterPress: true,
  },
];
