import { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { createAction } from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";

import {
  Bill,
  Order,
  PurchaseOrderItem,
  ExtraItem,
  Breakdown,
  Cost,
  Tax,
} from "../../types/bills";

import { saveBill } from "../../redux/bills";

type DraftBy<T, K extends keyof T> = Omit<T, K> & {[key in K]: string};

export type DraftExtraItem = DraftBy<ExtraItem, "quantity" | "unitCost">;
export type DraftBreakdown = DraftBy<Breakdown, "quantity" | "unitCost">;
export type DraftCost = DraftBy<Cost, "quantity" | "unitCost">;
export type DraftPurchaseOrderItem = Omit<PurchaseOrderItem, "breakdowns" | "costs"> & {
  breakdowns: DraftBreakdown[];
  costs: DraftCost[];
}
type DraftItem = DraftExtraItem | DraftPurchaseOrderItem;
type DraftOrder = Omit<Order, "items"> & {
  items: DraftItem[];
}
export type DraftBill = Omit<Bill, "orders"> & {
  orders: DraftOrder[];
};

function toDraft(bill: Bill): DraftBill {
  return {
    ...bill,
    orders: bill.orders.map(
      o => ({
        ...o,
        items: o.items.map(
          i => {
            if ("breakdowns" in i) {
              return {
                ...i,
                breakdowns: i.breakdowns.map(
                  b => ({
                    ...b,
                    quantity: b.quantity.toFixed(0),
		    unitCost: b.unitCost.toFixed(2),
                  })
                ),
                costs: i.costs.map(
                  c => ({
                    ...c,
                    quantity: c.quantity.toFixed(0),
		    unitCost: c.unitCost.toFixed(2),
                  })
                ),
              };
            } else {
              return {
                ...i,
                quantity: i.quantity.toFixed(0),
		unitCost: i.unitCost.toFixed(2),
              };
            }
          }
        )
      })
    )
  };
}

function fromDraft(draft: DraftBill): Bill {
  return {
    ...draft,
    orders: draft.orders.map(
      o => ({
        ...o,
        items: o.items.map(
          i => {
            if ("breakdowns" in i) {
              return {
                ...i,
                breakdowns: i.breakdowns.map(
                  b => ({
                    ...b,
                    quantity: Number(b.quantity),
		    unitCost: Number(b.unitCost),
                  })
                ),
                costs: i.costs.map(
                  c => ({
                    ...c,
                    quantity: Number(c.quantity),
		    unitCost: Number(c.unitCost),
                  })
                ),
              };
            } else {
              return {
                ...i,
                quantity: Number(i.quantity),
		unitCost: Number(i.unitCost),
              };
            }
          }
        )
      })
    )
  };
}

function isValidTotal(value: string): boolean {
  const total = Number(value);
  return value !== "" && !Number.isNaN(total) && Number.isFinite(total) && total >= 0;
}

function isValidQuantity(value: string): boolean {
  const quantity = Number(value);
  return value !== "" && Number.isInteger(quantity) && quantity >= 0;
}

function isValidDraft(draft: DraftBill): boolean {
  return (
    draft.number !== "" &&
    draft.orders.length > 0 &&
    draft.orders.every(
      o => (
        o.items.every(
          i => (
            !("breakdowns" in i) ?
              isValidQuantity(i.quantity) && isValidTotal(i.unitCost) :
	      i.breakdowns.every(
                b => isValidQuantity(b.quantity) && isValidTotal(b.unitCost)
              ) && i.costs.every(
                c => isValidQuantity(c.quantity) && isValidTotal(c.unitCost)
              )
          )
        )
      )
    )
  );
}

interface Division {
  division_id: string;
  division_name: string;
  default_currency_id: string;
}

interface BillItemType {
  id?: string;
  name: string;
  description: string;
  cost: number;
  excludeFromMargin: boolean;
}

interface CodePayload {
  refId: string;
  billItemType: BillItemType;
}
interface DescriptionPayload {
  refId: string;
  description: string;
}
interface QuantityPayload {
  refId: string;
  quantity: string;
}
interface UnitCostPayload {
  refId: string;
  unitCost: string;
}
interface AddExtraItemPayload {
  orderId: string;
  purchaseOrderId?: string;
}

const setNotes = createAction<string>("bill/set-notes");
const setBillNumber = createAction<string>("bill/set-bill-number");
const setBillDate = createAction<Date>("bill/set-bill-date");
const setDueDate = createAction<Date>("bill/set-due-date");
const setDivision = createAction<Division>("bill/set-division");
const setGlobalTax = createAction<Tax>("bill/set-global-tax");
const setCode = createAction<CodePayload>("bill/set-code");
const setDescription = createAction<DescriptionPayload>("bill/set-description");
const setQuantity = createAction<QuantityPayload>("bill/set-quantity");
const setUnitCost = createAction<UnitCostPayload>("bill/set-unit-cost");
const addExtraItem = createAction<AddExtraItemPayload>("bill/add-extra-item");
const resetDraft = createAction<Bill>("bill/reset");

function draftReducer(state: DraftBill, action: Action): DraftBill {
  if (setNotes.match(action)) {
    return { ...state, notes: action.payload };
  } else if (setBillNumber.match(action)) {
    return { ...state, number: action.payload };
  } else if (setBillDate.match(action)) {
    return { ...state, dateBillDate: action.payload };
  } else if (setDueDate.match(action)) {
    return { ...state, dateDue: action.payload };
  } else if (setDivision.match(action)) {
    return {
      ...state,
      currency: action.payload.default_currency_id,
      division: {
        id: action.payload.division_id,
        name: action.payload.division_name,
      }
    };
  } else if (setGlobalTax.match(action)) {
    return {
      ...state,
      tax: action.payload, 
      orders: state.orders.map(
        o => ({
          ...o,
          items: o.items.map(i => ({ ...i, taxes: [action.payload] }))
        })
      )
    };
  } else if (setCode.match(action)) {
    return {
      ...state,
      orders: state.orders.map(
        o => ({
          ...o,
          items: o.items.map(
            i => action.payload.refId === i.refId && !("breakdowns" in i) ? {
              ...i,
	      code: action.payload.billItemType.name,
	      billItemTypeId: action.payload.billItemType.id,
	      description: action.payload.billItemType.description,
	      unitCost: action.payload.billItemType.cost.toFixed(2),
	      excludeFromMargin: action.payload.billItemType.excludeFromMargin,
	    } : i
	  )
        })
      )
    };
  } else if (setDescription.match(action)) {
    return {
      ...state,
      orders: state.orders.map(
        o => ({
          ...o,
          items: o.items.map(
            i => ("description" in i) && action.payload.refId === i.refId ? {
              ...i,
	      description: action.payload.description
	    } : i
	  )
        })
      )
    };
  } else if (setQuantity.match(action)) {
    return {
      ...state,
      orders: state.orders.map(
        o => ({
          ...o,
          items: o.items.map(
            i => ("breakdowns" in i) ? {
              ...i,
              breakdowns: i.breakdowns.map(
                b => action.payload.refId === b.refId ? {
                  ...b,
                  quantity: action.payload.quantity
                } : b
              ),
              costs: i.costs.map(
                c => action.payload.refId === c.refId ? {
                  ...c,
                  quantity: action.payload.quantity
                } : c
              )
            } : (
              action.payload.refId === i.refId ? {
                ...i,
                quantity: action.payload.quantity
              } : i
            )
          )
        })
      )
    };
  } else if (setUnitCost.match(action)) {
    return {
      ...state,
      orders: state.orders.map(
        o => ({
          ...o,
          items: o.items.map(
            i => ("breakdowns" in i) ? {
              ...i,
              breakdowns: i.breakdowns.map(
                b => action.payload.refId === b.refId ? {
                  ...b,
                  unitCost: action.payload.unitCost
                } : b
              ),
              costs: i.costs.map(
                c => action.payload.refId === c.refId ? {
                  ...c,
                  unitCost: action.payload.unitCost
                } : c
              )
            } : (
              action.payload.refId === i.refId ? {
                ...i,
                unitCost: action.payload.unitCost
              } : i
            )
          )
        })
      )
    };
  } else if (addExtraItem.match(action)) {
    return {
      ...state,
      orders: state.orders.map(
        o => action.payload.orderId === o.id ? {
          ...o,
	  items: o.items.concat({
            refId: `new-${o.items.length}`,
            purchaseOrderId: action.payload.purchaseOrderId,
            code: "",
	    description: "",
	    quantity: "0",
	    unitCost: "0",
	    excludeFromMargin: false,
	    taxes: [state.tax],
          })
        } : o
      )
    };
  } else if (resetDraft.match(action)) {
    return toDraft(action.payload);
  }
  return state;
}

export default function useDraft(bill: Bill) {
  const dispatch = useDispatch();
  const [draft, updateDraft] = useReducer(draftReducer, bill, toDraft);

  useEffect(() => {
    updateDraft(resetDraft(bill));
  }, [bill]);

  const isNew = !bill.id;
  const isEditable = !bill.dateExported;
  const isValid = isValidDraft(draft);
  const updater = {
    setBillNumber: (billNumber: string) => updateDraft(setBillNumber(billNumber)),
    setNotes: (notes: string) => updateDraft(setNotes(notes)),
    setDivision: (division: Division) => updateDraft(setDivision(division)),
    setGlobalTax: (tax: Tax) => updateDraft(setGlobalTax(tax)),
    setBillDate: (billDate: Date) => updateDraft(setBillDate(billDate)),
    setDueDate: (dueDate: Date) => updateDraft(setDueDate(dueDate)),
    setCode: (refId: string, billItemType: BillItemType) => updateDraft(setCode({ refId, billItemType })),
    setDescription: (refId: string, description: string) => updateDraft(setDescription({ refId, description })),
    setQuantity: (refId: string, quantity: string) => updateDraft(setQuantity({ refId, quantity })),
    setUnitCost: (refId: string, unitCost: string) => updateDraft(setUnitCost({ refId, unitCost })),
    addExtraItem: (orderId: string, purchaseOrderId?: string) => updateDraft(addExtraItem({ orderId, purchaseOrderId })),
    resetDraft: (bill: Bill) => updateDraft(resetDraft(bill)),
  };
  const save = async () => {
    if (!isValid) {
      return;
    }
    await dispatch(saveBill(fromDraft(draft)));
  };

  return {
    draft,
    isNew,
    isEditable,
    isValid,
    updater,
    save,
  };
}

export type DraftUpdater = ReturnType<typeof useDraft>["updater"];
