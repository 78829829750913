import {
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import { oauth, isZeroDate } from "../utils";
import {
  Bill,
  Order,
  PurchaseOrder,
  Project,
} from "../types/bills";

const adapter = createEntityAdapter({
  selectId: (bill: Bill) => bill.id,
});

interface BillState {
  loading: "idle" | "pending";
}

const initialState = {
  loading: "idle",
};

const slice = createSlice({
  name: "bills",
  initialState: adapter.getInitialState(initialState),
  reducers: {
    loading(state) {
      state.loading = "pending";
    },
    created(state, action) {
      adapter.addOne(state, action.payload);
      state.loading = "idle";
    },
    retrieved(state, action) {
      adapter.setOne(state, action.payload);
      state.loading = "idle";
    },
    updated(state, action) {
      adapter.updateOne(state, action.payload);
      state.loading = "idle";
    },
    deleted(state, action) {
      adapter.removeOne(state, action.payload);
      state.loading = "idle";
    },
  }
});

export const actions = slice.actions;

interface OrderMap {
  [key: string]: Order;
}

interface ProjectMap {
  [key: string]: Project;
}

interface PurchaseOrderMap {
  [key: string]: PurchaseOrder[];
}

function adaptBill(data: any): Bill {
  const projects: ProjectMap = Object.keys(data.projects).reduce(
    (o, order_id) => ({
      ...o,
      [String(order_id)]: {
        id: String(data.projects[order_id].job_id),
        number: Number(data.projects[order_id].job_number),
        name: String(data.projects[order_id].job_name),
        active: Boolean(data.projects[order_id].active)
      }
    }),
    {} as ProjectMap
  );
  const purchaseOrders = Object.values(data.purchase_orders).reduce(
    (o: PurchaseOrderMap, po: any) => ({
      ...o,
      [String(po.order_id)]: (o[String(po.order_id)] ?? []).concat({
        id: String(po.purchase_order_id),
        number: Number(po.form_number),
        active: Boolean(po.active)
      })
    }),
    {} as PurchaseOrderMap
  );
  const items = data.items.reduce(
    (o, i: any) => {
      let group = o[i.order_id] ?? [];
      if (i.purchase_order_item_id) {
        let item = group.find(
          ii => (
            ii.purchaseOrderId === i.purchase_order_id && ii.itemId === i.item_id
          )
        );
        if (!item) {
          item = {
            purchaseOrderId: String(i.purchase_order_id),
            refId: String(i.item_id),
            itemId: String(i.item_id),
            code: String(i.item_code),
            name: String(i.item_name),
            breakdowns: [],
            costs: [],
            taxes: i.tax_amounts.map(
              ta => ({
                id: !!ta.tax_amount_id ? String(ta.tax_amount_id) : undefined,
                taxId: String(ta.taxId),
                label: String(ta.label),
                percent: Number(ta.percent),
              })
            )
          };
          group.push(item);
        }

        if (i.item_cost_id) {
          item.costs.push({
            id: !!i.bill_item_id ? String(i.bill_item_id) : undefined,
            refId: String(i.purchase_order_item_id),
            purchaseOrderItemId: String(i.purchase_order_item_id),
            itemCostId: String(i.item_cost_id),
            description: String(i.description),
            unbilledQuantity: Number(i.unbilled_quantity),
            quantity: Number(i.quantity),
            unitCost: Number(i.unit_cost),
            excludeFromMargin: Boolean(i.exclude_from_margin),
          })
        } else {
          item.breakdowns.push({
            id: !!i.bill_item_id ? String(i.bill_item_id) : undefined,
            refId: String(i.purchase_order_item_id),
            purchaseOrderItemId: String(i.purchase_order_item_id),
            description: String(i.description),
            unbilledQuantity: Number(i.unbilled_quantity),
            quantity: Number(i.quantity),
            unitCost: Number(i.unit_cost),
          })
        }
      } else {
        let item = {
          id: String(i.bill_item_id),
          refId: String(i.bill_item_id),
          billItemTypeId: !!i.bill_item_type_id ? String(i.bill_item_type_id) : undefined,
          code: String(i.item_code),
          description: String(i.description),
          quantity: Number(i.quantity),
          unitCost: Number(i.unit_cost),
          excludeFromMargin: Boolean(i.exclude_from_margin),
          taxes: i.tax_amounts.map(
            ta => ({
              id: !!ta.tax_amount_id ? String(ta.tax_amount_id) : undefined,
              taxId: String(ta.taxId),
              label: String(ta.label),
              percent: Number(ta.percent),
            })
          )
        };
        group.push(item);
      }
      return {
        ...o,
        [i.order_id]: group
      };
    },
    {}
  );
  const orders = Object.values(data.orders).reduce(
    (o: OrderMap, order: any) => ({
      ...o,
      [String(order.order_id)]: {
        id: String(order.order_id),
        number: Number(order.form_number),
        currency: String(order.currency),
        active: Boolean(order.active),
        project: projects[String(order.order_id)],
        purchaseOrders: purchaseOrders[String(order.order_id)] ?? [],
        items: items[String(order.order_id)] ?? [],
      }
    }),
    {} as OrderMap
  );

  const bill = {
    id: !!data.bill_id ? String(data.bill_id) : undefined,
    number: data.bill_reference_number,
    dateBillDate: !isZeroDate(data.bill_date_billdate) ? new Date(data.bill_date_billdate) : undefined,
    dateDue: !isZeroDate(data.bill_date_due) ? new Date(data.bill_date_due) : undefined,
    division: {
      id: String(data.division.division_id),
      name: String(data.division.division_name),
    },
    currency: String(data.currency),
    exchangeRate: data.exchange_rate,
    notes: String(data.bill_memo),
    dateExported: !isZeroDate(data.bill_date_exported) ? new Date(data.bill_date_exported) : undefined,
    qboBillRef: !!data.qbo_bill_ref ? Number(data.qbo_bill_ref) : undefined,
    orders: Object.values(orders),
    zip2tax: Boolean(data.zip2tax),
    tax: {
      taxId: String(data.tax.tax_id),
      label: String(data.tax.label),
      percent: Number(data.tax.percent),
    }
  } as Bill;
  return bill;
}

const createBill = (billData: Bill) => async (dispatch) => {
  dispatch(actions.loading());
  const data = {
    actionName: "create-bill",
    division_id: billData.division.id,
    bill_reference_number: billData.number,
    bill_date_billdate: billData.dateBillDate.toLocaleDateString("en-CA"), // i.e. YYYY-mm-dd
    bill_date_due: billData.dateDue.toLocaleDateString("en-CA"), // i.e. YYYY-mm-dd
    bill_memo: billData.notes,
    items: billData.orders.reduce(
      (items, o) => items.concat(o.items.reduce(
        (items, i) => items.concat(
          ("breakdowns" in i) ? i.breakdowns.map(
            b => ({
              purchase_order_item_id: b.purchaseOrderItemId,
              quantity: b.quantity,
              unit_cost: b.unitCost,
            })
          ).concat(i.costs.map(
            c => ({
              purchase_order_item_id: c.purchaseOrderItemId,
              quantity: c.quantity,
              unit_cost: c.unitCost,
            })
          )) : {
            order_id: o.id,
            bill_item_type_id: i.billItemTypeId,
            item_code: i.code,
            description: i.description,
            quantity: i.quantity,
            unit_cost: i.unitCost,
          } 
	),
        []
      )),
      []
    )
  };
  const result = await oauth("POST", "bill", data);
  const bill = adaptBill(result.json.bill);
  dispatch(actions.created(bill));
  return bill;
};

const updateBill = (data: Bill) => async (dispatch) => {
  dispatch(actions.loading());
  const result = await oauth("PUT", `bill/${data.id}`, data);
  const bill = adaptBill(result.json.bill);
  dispatch(actions.updated(bill));
  return bill;
};

export const retrieveBill = (id: string) => async (dispatch) => {
  dispatch(actions.loading());
  const result = await oauth("GET", `bill/${id}`, {actionName: "retrieve-bill"});
  const bill = adaptBill(result.json.bill);
  dispatch(actions.retrieved(bill));
  return bill;
};

export const deleteBill = (id: string) => async (dispatch) => {
  dispatch(actions.loading());
  const result = await oauth("DELETE", `bill/${id}`);
  dispatch(actions.deleted(id));
};

export const deleteMultipleBills = (ids: string[]) => async (dispatch) => {
  dispatch(actions.loading());
  const result = await oauth("DELETE", "bill", { ids });
  ids.forEach(id => dispatch(actions.deleted(id)));
};

export const saveBill = (data: Bill) => {
  if (data.id) {
    return updateBill(data);
  } else {
    return createBill(data);
  }
};

export async function getDefaultBill(purchase_order_ids: string[]): Promise<Bill> {
  const result = await oauth("GET", "bill", { actionName: "prepare-bill", purchase_order_ids });
  const bill = adaptBill(result.json.bill);
  return bill;
}

export async function getDefaultBillFromPONumber(po_number: string): Promise<Bill> {
  try {
    const result = await oauth("GET", "bill", { actionName: "prepare-bill", po_number });
    const bill = adaptBill(result.json.bill);
    return bill;
  } catch (e) {
    throw `Purchase Order #${po_number} not found`;
  }
}
export async function getDefaultBillFromSONumber(form_number: string): Promise<Bill> {
  try {
    const result = await oauth("GET", "bill", { actionName: "prepare-bill", form_number });
    const bill = adaptBill(result.json.bill);
    return bill;
  } catch (e) {
    throw `Sales Order #${form_number} not found`;
  }
}

export const selectors = adapter.getSelectors();

export default slice.reducer;
