import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { colors, ConfirmPopup, EllipsisIcon, fontFamilies, Typography } from "@commonsku/styles";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { SupplierColumn } from "../common";
import { useReportContext } from "../../report_context";
import { oauth } from "../../../../utils";
import { useHasCapabilities, useIdentity } from "../../../../hooks";
import { deleteBill, retrieveBill } from "../../../../redux/bills";
import { Bill } from "../../../../types/bills";
import BillPopup from "../../../bills/BillPopup";
import DropdownMenu, { MenuTrigger } from "../../../DropdownMenu";
import ExportToAccountingPopup, {
  AccountingSoftware,
  exportToAccounting,
  getAccountingSoftwareName,
  AccountingExportReport
} from "../../../finance/ExportToAccountingPopup";
import { getDefaultBillVendorCredit } from "../../../../redux/vendor_credits";
import { VendorCredit } from "../../../../types/vendor_credit";
import VendorCreditPopup from "../../../vendor-credit/VendorCreditPopup";

export const BillReferenceNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Text
> = {
  header: "Invoice #",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["bill_id", "bill_reference_number"],
  accessorKey: "bill_reference_number",
  canSort: true,
  orderByField: "bill_reference_number",
  width: 83,
  dataTransform: ({ bill_id, bill_reference_number }) => ({
    text: bill_reference_number,
  }),
};

export const BillPurchaseOrdersColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Generic
> = {
  header: "Purchase Orders",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["purchase_orders", "division_id"],
  accessorKey: "purchase_orders",
  canSort: false,
  width: 105,
  dataTransform: ({ purchase_orders, division_id }) => ({
    cell: (
      <>
        {purchase_orders.map(
          po => (
            <div key={po.purchase_order_id}>
              <Typography.Span
                as="a"
                style={{
                  display: "block",
                  alignContent: "center",
                  color: colors.primary1.main,
                }}
                href={`/purchase_order.php?id=${po.purchase_order_id}`}
                target="_blank"
                rel="noreferrer"
                title={po.po_name}
              >
                {po.form_number}
                {division_id !== po.division_id && <>({po.division_name})</>}
              </Typography.Span>
            </div>
          )
        )}
      </>
    )  
  })
};


export const BillDateColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Bill Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["bill_date_billdate"],
  accessorKey: "bill_date_billdate",
  canSort: true,
  orderByField: "bill_date_billdate",
  width: 120,
};

export const DueDateColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Due Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["bill_date_due"],
  accessorKey: "bill_date_due",
  canSort: true,
  orderByField: "bill_date_due",
  width: 120,
};

export const AmountDueColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Amount Due",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["bill_amount_due", "currency_id"],
  accessorKey: "bill_amount_due",
  canSort: true,
  orderByField: "bill_amount_due",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ bill_amount_due, currency_id }) => ({
    amount: bill_amount_due,
    currency_id,
  }),
};

const StyledDropdownMenu = styled(DropdownMenu)`
& > .dropdown-menu-trigger {
  color: ${colors.primary1.main};
  border-radius: 5px;
  padding: 4px 4px 0 4px;
}
&.dropdown-menu-open > .dropdown-menu-trigger {
  border: 2px solid ${colors.primary1.main};
}
&&& > ul {
  border: 2px solid ${colors.primary1.main};
  border-radius: 5px;
  margin-top: 4px;
  right: 1rem;
  min-width: 200px;

  & > li {
    font-family: ${fontFamilies.regular};
    color: ${colors.neutrals.bodyText};
  }
}
`;

function BillActions({ bill_id }: { bill_id: string }) {
  const [accountingExportReport, setAccountingExportReport] = useState<AccountingExportReport | null | false>(false);
  const identity = useIdentity();
  const accountingSoftware = (identity.accounting_software ?? "CSV") as AccountingSoftware;
  const exportLabel = `Export to ${getAccountingSoftwareName(accountingSoftware)}`;
  const canDeleteBill = useHasCapabilities(["DELETE-BILL"]);
  const canExport = useHasCapabilities(["EXPORT-REPORT"]);
  const canEditBill = useHasCapabilities(["MODIFY-BILL"]);
  const canCreateVendorCredit = useHasCapabilities(["CREATE-VENDOR-CREDIT"]);
  const { fetchReport } = useReportContext();
  const [showConfirm, setShowConfirm] = useState(false);
  const [bill, setBill] = useState<Bill | undefined>();
  const [vendorCredit, setVendorCredit] = useState<VendorCredit | undefined>();
  const dispatch = useDispatch();

  const bill_ids = useMemo(
    () => [bill_id],
    [bill_id]
  );

  const handleExport = useCallback(async () => {
    try {
      setAccountingExportReport(null);
      const report = await exportToAccounting("BILL", accountingSoftware, bill_ids);
      if (report) {
        setAccountingExportReport(report);
      } else {
        setAccountingExportReport(false);
        fetchReport();
      }
    } catch (e) {
      setAccountingExportReport(null);
      toast.error("Unable to export bill");
    }
  }, [accountingSoftware, bill_ids]);

  const handleClose = useCallback(() => {
    setAccountingExportReport(false);
    fetchReport();
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      await dispatch(deleteBill(bill_id));
      fetchReport();
    } catch (e) {
      toast.error("Unable to delete bill");
    } finally {
      setShowConfirm(false);
    }
  }, [bill_id]);

  const options = useMemo(
    () => [
      {
        value: exportLabel,
        hideOnClick: true,
        onClick: handleExport,
        show: canExport,
      },
      {
        value: "Edit",
        hideOnClick: true,
        onClick: async () => {
          const bill = await retrieveBill(bill_id)(dispatch);
          setBill(bill);
        },
        show: canEditBill,
      },
      {
        value: "Create Vendor Credit",
        hideOnClick: true,
        onClick: async () => {
          const vendorCredit = await getDefaultBillVendorCredit(bill_id);
          setVendorCredit(vendorCredit);
        },
        show: canCreateVendorCredit,
      },
      {
        value: <span style={{ color: colors.error }}>Delete</span>,
        hideOnClick: true,
        onClick: () => setShowConfirm(true),
        show: canDeleteBill,
      }
    ].filter(
      o => o.show
    ),
    [bill_id, dispatch]
  );
  const onClose = useCallback(
    () => {
      setVendorCredit(undefined);
    },
    []
  );
  return (
    <>
      {showConfirm && (
        <ConfirmPopup
          confirmText="Are you sure you want to delete?  Unpaid commissions will be recalculated."
          onClose={() => setShowConfirm(false)}
          onDelete={handleDelete}
        />
      )}
      {bill && <BillPopup bill={bill} onClose={handleClose} />}
      {vendorCredit && <VendorCreditPopup vendorCredit={vendorCredit} onClose={onClose} />}
      {false !== accountingExportReport && <ExportToAccountingPopup
        entityType="BILL"
        ids={bill_ids}
        onClose={handleClose}
        report={accountingExportReport}
      />}
      <StyledDropdownMenu options={options} align="right">
       <MenuTrigger><EllipsisIcon altText="Actions" /></MenuTrigger>
      </StyledDropdownMenu>
    </>
  );
}

const ActionColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Generic> =
{
  header: "Actions",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["bill_id"],
  accessorKey: "bill_id",
  canSort: false,
  width: 120,
  justify: "center",
  dataTransform: ({ bill_id }) => ({
    cell: <BillActions bill_id={bill_id} />
  })
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  BillReferenceNumberColumn,
  BillPurchaseOrdersColumn,
  SupplierColumn,
  BillDateColumn,
  DueDateColumn,
  AmountDueColumn,
  ActionColumn,
];
