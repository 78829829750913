import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { colors, Row, Col, Input, Datepicker, LabeledIconInput, ChevronIcon } from "@commonsku/styles";

import { createFinalizeAddTax } from "../../actions/tax";
import {
  Bill,
  PurchaseOrderItem,
  ExtraItem,
  Breakdown,
  Cost,
  Tax
} from "../../types/bills";

import useDraft, { DraftBreakdown, DraftCost, DraftExtraItem, DraftPurchaseOrderItem, DraftUpdater } from "./useDraft";
import FormPopup from "../FormPopup";
import TaxSelect from "../TaxSelect";
import AsyncDivisionSelect from "../AsyncDivisionSelect";
import BillItemTypeSelect from "./BillItemTypeSelect";
import { getCurrencySymbol, formatMoneyCurrency, formatPercent } from "../../utils";

const BILL_NOTE_PLACEHOLDER_TEXT = "Add a note (optional)";

const BillDetails = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.primary1["65"]};
  border-radius: 8px;
  background: ${colors.white};
`;

const BillDetailsHeader = styled.div`
&& {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  height: 3.5rem;
  padding: 0 0.5rem;
  background: ${colors.neutrals.bg1};
  border-radius: 8px 8px 0 0;
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 700;
}
`;

const FormDescription = styled.div`
&& {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap 2rem;
  padding: 0;
  margin: 0
}
`;

const BillItemHeader = styled.div`
&& {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  background: ${colors.neutrals.bg1};
  font-size: 14px;
  font-weight: 600;
  line-height: 1rem;
  height: 3.5rem;
}
`;

const BillItemRow = styled.div`
&& {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  height: 3.5rem;
}
`;

const PurchaseOrder = styled.span`
  font-weight: 700;
`;

const Project = styled.span`
  font-weight: 400;
`;

const FlexCol = styled(Col)`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 1rem;

  && {
    margin: 1rem;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  &&& > :nth-child(2) {
    width: 70%;
  }
`;

const Label = styled.div`
&&& {
  font-weight: 700;
  width: 30%;
  padding: 0.5rem 0;
}
`;

const DetailInput = styled(Input)`
&&& {
  margin: 0;
}
`;

const ToggleCell = styled.div`
&&& {
  flex: 0.5 0.5 3%;
  text-align: center;
  padding-left: 1rem;

  & > input {
    margin: 0;
  }
}
`;

const CodeCell = styled.div`
&&& {
  display: flex;
  align-items: center;
  flex: 1 1 7%;
  text-align: left;
  height: 100%;
}
`;

const NameCell = styled.div`
  flex: 3 3 21%;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const QuantityCell = styled.div`
  flex: 1 1 7%;
  text-align: center;
`;

const UnitCostCell = styled.div`
  flex: 2 2 14%;
  text-align: right;
`;

const SubtotalCell = styled.div`
  flex: 1 1 7%;
  text-align: right;
`;

const TaxCell = styled.div`
  flex: 2 2 14%;
  text-align: left;
`;

const TotalCell = styled.div`
&&& {
  flex: 1 1 7%;
  text-align: right;
}`;

const ActionCell = styled.div`
  flex: 2 2 14%;
  text-align: center;
`;

const ActionLink = styled.a`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 0.875rem;
`;

const CodeInput = styled(BillItemTypeSelect)`
&&& {
  text-align: center;
  margin: 0;
  width: 100%;
}
`;

const DescriptionInput = styled(Input)`
&&& {
  text-align: left;
  margin: 0;
  width: 100%;
}
`;

const QuantityInput = styled(Input)`
&&& {
  text-align: center;
  margin: 0;
  width: 100%;
}
`;

const CostInput = styled(LabeledIconInput)`
&&& {
  text-align: center;
  margin: 0;
  width: 100%;
}
`;

const getSubtotal = (item) => Number(item.unitCost) * Number(item.quantity);
const getTotal = (item, item_taxes = []) => {
  const subtotal = getSubtotal(item);
  return subtotal + (item.taxes ?? item_taxes).reduce((taxes, t) => taxes + Math.round(subtotal * t.percent) / 100, 0);
}
const getTaxes = (item) => Array.from(
  new Set(
    item.taxes.map(tax => `${tax.label} ${formatPercent(tax.percent)}`)
  )
).sort();

const getProductSubtotal = (item) => item.breakdowns.reduce(
  (t, b) => t + getSubtotal(b),
  0
) + item.costs.reduce(
  (t, c) => t + getSubtotal(c),
  0
);

const getProductTotal = (item) => {
  const subtotal = getProductSubtotal(item);
  return subtotal + item.taxes.reduce((taxes, t) => taxes + Math.round(subtotal * t.percent) / 100, 0);
}

const getBillSubtotal = (bill, uncheckedItems) => bill.orders.reduce(
  (t, o) => t + o.items.filter(
    (i) => !uncheckedItems[i.refId]
  ).reduce(
    (tt, i) => tt + (("breakdowns" in i) ? getProductSubtotal(i) : getSubtotal(i)),
    0
  ),
  0
);

const getBillTotal = (bill, uncheckedItems) => bill.orders.reduce(
  (t, o) => t + o.items.filter(
    (i) => !uncheckedItems[i.refId]
  ).reduce(
    (tt, i) => tt + (("breakdowns" in i) ? getProductTotal(i) : getTotal(i)),
    0
  ),
  0
);

const getBillTax = (bill, uncheckedItems) => getBillTotal(bill, uncheckedItems) - getBillSubtotal(bill, uncheckedItems);

interface ToggledItems {
  [id: string]: boolean;
}

interface TaxMap {
  [taxId: string]: Tax;
}

interface BreakdownRowProps {
  breakdown: DraftBreakdown;
  item: DraftPurchaseOrderItem;
  currency: string;
  updater: DraftUpdater;
  disabled: boolean;
}

function BreakdownRow({ breakdown, item, currency, updater, disabled }: BreakdownRowProps) {
  return (
    <BillItemRow key={breakdown.refId}>
      <ToggleCell>
        &nbsp;
      </ToggleCell>
      <CodeCell>
        &nbsp;
      </CodeCell>
      <NameCell title={breakdown.description}>
        {breakdown.description}
      </NameCell>
      <QuantityCell>
        <QuantityInput value={breakdown.quantity} disabled={disabled} onChange={(e) => updater.setQuantity(breakdown.refId, e.target.value)} />
      </QuantityCell>
      <UnitCostCell>
        <CostInput Icon={<span>{getCurrencySymbol("en-US", currency)}</span>} value={breakdown.unitCost} disabled={disabled} onChange={(e) => updater.setUnitCost(breakdown.refId, e.target.value)} />
      </UnitCostCell>
      <SubtotalCell>
        {formatMoneyCurrency(getSubtotal(breakdown), currency)}
      </SubtotalCell>
      <TaxCell>
        {getTaxes(item).join(", ")}
      </TaxCell>
      <TotalCell>
        {formatMoneyCurrency(getTotal(breakdown, item.taxes), currency)}
      </TotalCell>
      <ActionCell>
        &nbsp;
      </ActionCell>
    </BillItemRow>
  );
}

interface CostRowProps {
  cost: DraftCost;
  item: DraftPurchaseOrderItem;
  currency: string;
  updater: DraftUpdater;
  disabled: boolean;
}

function CostRow({ cost, item, currency, updater, disabled }: CostRowProps) {
  return (
    <BillItemRow>
      <ToggleCell>
        &nbsp;
      </ToggleCell>
      <CodeCell>
        &nbsp;
      </CodeCell>
      <NameCell title={cost.description}>
        {cost.description}
      </NameCell>
      <QuantityCell>
        <QuantityInput value={cost.quantity} disabled={disabled} onChange={(e) => updater.setQuantity(cost.refId, e.target.value)} />
      </QuantityCell>
      <UnitCostCell>
        <CostInput Icon={<span>{getCurrencySymbol("en-US", currency)}</span>} value={cost.unitCost} disabled={disabled} onChange={(e) => updater.setUnitCost(cost.refId, e.target.value)} />
      </UnitCostCell>
      <SubtotalCell>
        {formatMoneyCurrency(getSubtotal(cost), currency)}
      </SubtotalCell>
      <TaxCell>
        {getTaxes(item).join(", ")}
      </TaxCell>
      <TotalCell>
        {formatMoneyCurrency(getTotal(cost, item.taxes), currency)}
      </TotalCell>
      <ActionCell>
        &nbsp;
      </ActionCell>
    </BillItemRow>
  );
}

interface PurchaseOrderItemRowProps {
  item: DraftPurchaseOrderItem;
  checked: boolean;
  onToggleChecked: () => void;
  currency: string;
  updater: DraftUpdater;
  disabled: boolean;
}

function PurchaseOrderItemRow(
  {
    item,
    checked,
    onToggleChecked,
    currency,
    updater,
    disabled,
  } : PurchaseOrderItemRowProps
) {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <>
      <BillItemRow>
        <ToggleCell>
          <input
            type="checkbox"
            checked={checked}
            onChange={onToggleChecked}
          />
        </ToggleCell>
        <CodeCell>
          {item.code}
        </CodeCell>
        <NameCell title={item.name}>
          {item.name}
        </NameCell>
        <QuantityCell>
          <>&nbsp;</>
        </QuantityCell>
        <UnitCostCell>
          <>&nbsp;</>
        </UnitCostCell>
        <SubtotalCell>
          {formatMoneyCurrency(getProductSubtotal(item), currency)}
        </SubtotalCell>
        <TaxCell>
          {getTaxes(item).join(", ")}
        </TaxCell>
        <TotalCell>
          {formatMoneyCurrency(getProductTotal(item), currency)}
        </TotalCell>
        <ActionCell>
          {(item.breakdowns.length > 0 || item.costs.length > 0) && (
            <ActionLink onClick={() => setCollapsed(!collapsed)}>
              {collapsed ?
                <><ChevronIcon direction="down" />View/Edit</>:
                <><ChevronIcon direction="up" />Collapse</>
              }
            </ActionLink>
          )}
        </ActionCell>
      </BillItemRow>
      {!collapsed && item.breakdowns.map(
        (b) => (
          <BreakdownRow
            key={b.refId}
            breakdown={b}
            item={item}
            currency={currency}
            updater={updater}
            disabled={disabled}
          />
        )
      )}
      {!collapsed && item.costs.map(
        (c) => (
          <CostRow
            key={c.refId}
            cost={c}
            item={item}
            currency={currency}
            updater={updater}
            disabled={disabled}
          />
        )
      )}
    </>
  );
}

interface ExtraItemRowProps {
  item: DraftExtraItem;
  checked: boolean;
  onToggleChecked: () => void;
  currency: string;
  updater: DraftUpdater;
  disabled: boolean;
}

function ExtraItemRow({
  item,
  checked,
  onToggleChecked,
  currency,
  updater,
  disabled
}: ExtraItemRowProps) {
  return (
    <BillItemRow>
      <ToggleCell>
        {disabled ? <>&nbsp;</> : <input
          type="checkbox"
          checked={checked}
          onChange={onToggleChecked}
        />}
      </ToggleCell>
      <CodeCell>
        <CodeInput value={item.billItemTypeId ?? item.code} disabled={disabled} onChange={(bit) => updater.setCode(item.refId, bit)} />
      </CodeCell>
      <NameCell title={item.description}>
        <DescriptionInput value={item.description} disabled={disabled} onChange={(e) => updater.setDescription(item.refId, e.target.value)} />
      </NameCell>
      <QuantityCell>
        <QuantityInput value={item.quantity} disabled={disabled} onChange={(e) => updater.setQuantity(item.refId, e.target.value)} />
      </QuantityCell>
      <UnitCostCell>
        <CostInput Icon={<span>{getCurrencySymbol("en-US", currency)}</span>} value={item.unitCost} disabled={disabled} onChange={(e) => updater.setUnitCost(item.refId, e.target.value)} />
      </UnitCostCell>
      <SubtotalCell>
        {formatMoneyCurrency(getSubtotal(item), currency)}
      </SubtotalCell>
      <TaxCell>
        {getTaxes(item).join(", ")}
      </TaxCell>
      <TotalCell>
        {formatMoneyCurrency(getTotal(item, item.taxes), currency)}
      </TotalCell>
      <ActionCell>
        <>&nbsp;</>
      </ActionCell>
    </BillItemRow>
  );
}

export default function BillPopup(
  {
    bill,
    onClose,
  }: {
    bill: Bill,
    onClose: () => void,
  }
) {
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(
        createFinalizeAddTax(
          {
            tax_id: bill.tax.taxId,
            label: bill.tax.label,
            percent: bill.tax.percent
          }
        )
      );
      bill.orders.forEach(
        o => o.items.forEach(
          i => i.taxes.forEach(
            t => dispatch(
              createFinalizeAddTax(
                {
                  tax_id: t.taxId,
                  label: t.label,
                  percent: t.percent
                }
              )
            )
          )
        )
      );
    },
    [bill]
  );
  const taxes = useSelector(
    (state: any) => Object.values(state.entities.taxes).reduce(
      (o: TaxMap, t: any) => ({
        ...o,
        [String(t.tax_id)]: {
          taxId: String(t.tax_id),
          label: String(t.label),
          percent: Number(t.percent),
        }
      }),
      {
        [bill.tax.taxId]: bill.tax
      } as TaxMap
    )
  );
  const [uncheckedItems, setUnCheckedItems] = useState<ToggledItems>({});
  const { draft, isNew, isValid, isEditable, updater, save } = useDraft(bill);

  const handleError = useCallback(
    (error: unknown) => toast.error(
      isNew ?
        "Unable to create bill" :
        "Unable to update bill"
    ),
    [isNew]
  );

  const title = isNew ?  "Create Bill" : `Bill ${draft.number}`;

  return (
    <FormPopup
      title={title}
      onClose={onClose}
      onSave={save}
      onError={handleError}
      isNew={isNew}
      isValid={isValid}
      isEditable={isEditable}
    >
      <BillDetails>
        <BillDetailsHeader>Details</BillDetailsHeader>
        <Row>
          <FlexCol>
            <LabelContainer>
              <Label>Supplier</Label>
              <AsyncDivisionSelect
                value={draft.division.id}
                onChange={(division) => updater.setDivision(division)}
                isDisabled={!isEditable}
              />
            </LabelContainer>
            <LabelContainer>
              <Label>Invoice #</Label>
              <DetailInput value={draft.number} disabled={!isEditable} onChange={(e) => updater.setBillNumber(e.target.value)} />
            </LabelContainer>
            <LabelContainer>
              <Label>Invoice Date</Label>
              <Datepicker value={draft.dateBillDate} disabled={!isEditable} onChange={(date) => updater.setBillDate(date)} />
            </LabelContainer>
            <LabelContainer>
              <Label>Due Date</Label>
              <Datepicker value={draft.dateDue} disabled={!isEditable} onChange={(date) => updater.setDueDate(date)} />
            </LabelContainer>
          </FlexCol>
          <FlexCol>
            <LabelContainer>
              <Label>Bill Currency</Label>
              <div>{draft.currency}</div>
            </LabelContainer>
            <LabelContainer>
              <Label>Order Currency</Label>
              <div>{bill.orders[0]?.currency ?? draft.currency}</div>
            </LabelContainer>
            {draft.currency !== (bill.orders[0]?.currency ?? draft.currency) && <LabelContainer>
              <Label>Currency Conversion</Label>
              <div>1 {draft.currency} = {bill.exchangeRate}{bill.orders[0]?.currency ?? draft.currency}</div>
            </LabelContainer>}
            <LabelContainer>
              <Label>Notes</Label>
              <DetailInput value={draft.notes} disabled={!isEditable} onChange={(e) => updater.setNotes(e.target.value)} />
            </LabelContainer>
          </FlexCol>
          <FlexCol>
            <LabelContainer>
              <Label>Subtotal</Label>
              <div>{formatMoneyCurrency(getBillSubtotal(draft, uncheckedItems), draft.currency)}</div>
            </LabelContainer>
            <LabelContainer>
              <Label>Global Tax</Label>
              <TaxSelect
                style={{ width: "100%" }}
                value={draft.tax.taxId}
                zip2tax={draft.zip2tax}
                onNewTax={(tax) => {
                  taxes[String(tax.tax_id)] = {
                    taxId: String(tax.tax_id),
                    label: String(tax.label),
                    percent: Number(tax.percent),
                  };
                }}
                disabled={!isEditable}
                onChange={(taxId) => updater.setGlobalTax(taxes[taxId])}
              />
            </LabelContainer>
            <LabelContainer>
              <Label>Total Tax</Label>
              <div>{formatMoneyCurrency(getBillTax(draft, uncheckedItems), draft.currency)}</div>
            </LabelContainer>
            <LabelContainer>
              <Label>Amount Due</Label>
              <div>{formatMoneyCurrency(getBillTotal(draft, uncheckedItems), draft.currency)}</div>
            </LabelContainer>
          </FlexCol>
        </Row>
      </BillDetails>
      {draft.orders.map(
        o => o.purchaseOrders.map(
          po => (
            <BillDetails key={po.id}>
              <BillDetailsHeader>
                <FormDescription>
                  <PurchaseOrder>
                    PO
                    <a
                      href={`/project/${o.project.number}/production`}
                      target="_blank"
                    >
                      #{po.number}
                    </a>
                  </PurchaseOrder>
                  <Project>
                    Project
                    <a
                      href={`/project/${o.project.number}`}
                      target="_blank"
                    >
                      #{o.project.number}
                    </a>
                  </Project>
                </FormDescription>
                {isEditable && <ActionLink onClick={() => updater.addExtraItem(o.id, po.id)}>+ Add Bill Item</ActionLink>}
              </BillDetailsHeader>
              <BillItemHeader>
                <ToggleCell>&nbsp;</ToggleCell>
                <CodeCell>Code</CodeCell>
                <NameCell>Item Name</NameCell>
                <QuantityCell>QTY</QuantityCell>
                <UnitCostCell>Unit Cost</UnitCostCell>
                <SubtotalCell>Subtotal</SubtotalCell>
                <TaxCell>Tax</TaxCell>
                <TotalCell>Total</TotalCell>
                <ActionCell>&nbsp;</ActionCell>
              </BillItemHeader>
              {o.items.filter(
                 i => i.purchaseOrderId === po.id
               ).map(
                 (i) => ("breakdowns" in i) ? (
                   <PurchaseOrderItemRow 
                     key={i.refId}
                     item={i}
                     checked={!uncheckedItems[i.refId]}
                     onToggleChecked={() => setUnCheckedItems(
                       ci => ({
                         ...ci,
                         [i.refId]: !ci[i.refId] 
                       })
                     )}
                     currency={draft.currency}
                     updater={updater}
                     disabled={!isEditable}
                   />
                 ) : (
                   <ExtraItemRow
                     key={i.refId}
                     item={i}
                     checked={!uncheckedItems[i.refId]}
                     onToggleChecked={() => setUnCheckedItems(
                       ci => ({
                         ...ci,
                         [i.refId]: !ci[i.refId] 
                       })
                     )}
                     currency={draft.currency}
                     updater={updater}
                     disabled={!isEditable}
                   />
                 )
               )}
            </BillDetails>
          )
        ).concat(
          o.items.filter(i => !i.purchaseOrderId).length > 0 || o.purchaseOrders.length === 0 ? (
            <BillDetails key={o.id}>
              <BillDetailsHeader>
                <FormDescription>
                  <PurchaseOrder>
                    Order
                    <a
                      href={`/project/${o.project.number}/sales-order/${o.number}`}
                      target="_blank"
                    >
                      #{o.number}
                    </a>
                  </PurchaseOrder>
                  <Project>
                    Project
                    <a
                      href={`/project/${o.project.number}`}
                      target="_blank"
                    >
                      #{o.project.number}
                    </a>
                  </Project>
                </FormDescription>
                {isEditable && <ActionLink onClick={() => updater.addExtraItem(o.id)}>+ Add Bill Item</ActionLink>}
              </BillDetailsHeader>
              {o.items.filter(
                 i => !i.purchaseOrderId
               ).map(
                 (i: DraftExtraItem) => (
                   <ExtraItemRow
                     key={i.refId}
                     item={i}
                     checked={!uncheckedItems[i.refId]}
                     onToggleChecked={() => setUnCheckedItems(
                       ci => ({
                         ...ci,
                         [i.refId]: !ci[i.refId] 
                       })
                     )}
                     currency={draft.currency}
                     updater={updater}
                     disabled={!isEditable}
                   />
                 )
               )}
            </BillDetails>
          ) : []
        )
      )}
    </FormPopup>
  );
}
