import React from "react";
import styled from "styled-components";
import { Csku, SkubotSpinner } from "@commonsku/styles";

import { useReportContext } from "./report_context";
import { STYLE_CONSTANTS } from "./config/styles";

const { SIDE_BAR_WIDTH } = STYLE_CONSTANTS;

const LoadingContainer = styled(Csku)<{ fillPage?: boolean }>`
  &&& {
    position: absolute;
    display: flex;
    top: 0;
    padding-top: min(200px, calc(50% - 100px));
    left: ${props => props.fillPage ? SIDE_BAR_WIDTH : 0}px;
    width: calc(100% - ${props => props.fillPage ? SIDE_BAR_WIDTH : 0}px);
    height: 100%;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(2px);
    z-index: 2;
  }
`;

interface LoadingProps {
  fillPage?: boolean;
}

const Loading = ({ fillPage = false }: LoadingProps) => {
  const { loading } = useReportContext();

  return loading ? (
    <LoadingContainer fillPage={fillPage}>
      <SkubotSpinner />
    </LoadingContainer>
  ) : null;
};

export default Loading;
