export interface ReportElementSizes {
    SIDE_BAR_WIDTH: number;
    PREFERRED_WINDOW_WIDTH: number;
    TABLE_ELEMENTS_GAP: number;
    SUMMARY_CONTAINER_HEIGHT: number;
    TABLE_PAGE_HEADER_HEIGHT: number;
    ROW_HEIGHT: number;
    TABLE_BOTTOM_MARGIN: number;
    BULK_ACTION_BAR_HEIGHT: number;
}

export const STYLE_CONSTANTS: ReportElementSizes = {
    SIDE_BAR_WIDTH: 192,
    PREFERRED_WINDOW_WIDTH: 1512,
    TABLE_ELEMENTS_GAP: 16,
    SUMMARY_CONTAINER_HEIGHT: 87,
    TABLE_PAGE_HEADER_HEIGHT: 158, // 72 + 16*2 + 54; 72 for header, 16 for gap, 54 for Report title
    ROW_HEIGHT: 56,
    TABLE_BOTTOM_MARGIN: 112, // for pagination and action buttons
    BULK_ACTION_BAR_HEIGHT: 69,
} as const;
