import React, { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { Button } from "@commonsku/styles";
import { BulkActionConfigBase } from "../index";
import { oauth, fetchCsv, createDownload } from "../../../../utils";
import { useReportContext } from "../../report_context";
import { prepareSelectBillsLabel as prepareLabel, DeleteBillsButton } from "../common/bulk-actions";

export function ExportSelectedToCsvButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing?: boolean) => void,
    disabled?: boolean,
  }
) {
  const bill_ids: string[] = useMemo(
    () => selected.map((row: any) => row.bill_reference_number.bill_id),
    [selected]
  );

  const handleClick = useCallback(async () => {
    setExecuting(true);
    try {
      const params = {
        reexport: "1",
      };

      const CHUNK_SIZE = 10;
      const chunks = [...Array(Math.ceil(bill_ids.length / CHUNK_SIZE))].map(
        (_, index) => bill_ids.slice(index & CHUNK_SIZE, index * CHUNK_SIZE + CHUNK_SIZE)
      );
     
      const rows = await Promise.all(
        chunks.map(
           chunk => {
             const params = new URLSearchParams();
             params.append("reexport", "1");
             chunk.forEach(bill_id => params.append("bill_ids[]", bill_id));
             return fetchCsv(
               `bill/quickbooks-export?${params.toString()}`
             ).then(
               blob => blob.text()
             );
          }
        )
      );
      const headers = rows[0].split("\n").shift() + "\n";
      const csv = headers + rows.map(
        row => row.split("\n").filter(Boolean).slice(1).join("\n")
      ).join("\n");
      await createDownload("data:text/plain;charset=utf-8," + encodeURIComponent(csv), "bill.csv");
    } catch (e) {
      toast.error("Unable to export bills to CSV");
    } finally {
      setExecuting(false);
    }
  }, [setExecuting, bill_ids]);

  return (
    <Button
      disabled={disabled}
      onClick={handleClick}
    >
      Export to CSV
    </Button>
  );
}


export function UnExportFromAccountingButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing?: boolean) => void,
    disabled?: boolean,
  }
) {
  const { fetchReport } = useReportContext();
  const bill_ids = useMemo(
    () => selected.map((row: any) => row.bill_reference_number.bill_id),
    [selected]
  );

  const handleClick = useCallback(async () => {
    if (bill_ids.length === 1) {
      if (!confirm("Are you sure to want to un-export this bill?")) {
        return;
      }
    } else {
      if (!confirm("Are you sure to want to un-export these bills?")) {
        return;
      }
    }
    setExecuting(true);
    try {
      await oauth("POST", "bill", { action: "unexport", ids: bill_ids });
      if (bill_ids.length === 1) {
        toast.success("1 bill unexported");
      } else {
        toast.success(`${bill_ids.length} bills unexported`);
      }
      await fetchReport();
    } catch (e) {
      toast.error("Unable to un-export bills");
    } finally {
      setExecuting(false);
    }
  }, [setExecuting, bill_ids]);

  return (
    <Button
      disabled={disabled}
      onClick={handleClick}
    >
      Un-Export
    </Button>
  );
}

export const BulkActions: BulkActionConfigBase = {
  prepareLabel,
  actions: [
    DeleteBillsButton,
    UnExportFromAccountingButton,
    ExportSelectedToCsvButton,
  ]
};
