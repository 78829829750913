import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";

const ClientRepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Client Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "client_rep_user_id",
    "client_rep_first_name",
    "client_rep_last_name",
    "client_rep_avatar_file_id",
  ],
  accessorKey: "client_rep",
  canSort: true,
  orderByField: "client_rep",
  width: 50,
  dataTransform: ({
    client_rep_first_name,
    client_rep_last_name,
    client_rep_avatar_file_id,
  }) => ({
    full_name: `${client_rep_first_name} ${client_rep_last_name}`,
    avatar_file_id: client_rep_avatar_file_id,
  }),
};

const OrderRepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Order Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_user_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id",
  ],
  accessorKey: "sales_rep",
  canSort: true,
  orderByField: "sales_rep",
  width: 50,
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};

const ProjectDetailsColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Project
> = {
  header: "Project",
  type: BASE_COLUMN_TYPES.Project,
  fields: [
    "job_id",
    "job_number",
    "job_name",
    "items",
    "job_active",
    "order_id",
    "deleted_orders",
  ],
  accessorKey: "job",
  canSort: true,
  orderByField: "job_number",
  width: 250,
};

function getOrderHref(job_number, order_type, form_number) {
  if ('OPPORTUNTIY' === order_type) {
    return `/project/${job_number}`;
  }
  return `/project/${job_number}/${order_type.toLowerCase().replaceAll(' ', '-')}/${form_number}`;
}

const StatusColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Status> = {
  header: "Stage",
  type: BASE_COLUMN_TYPES.Status,
  fields: [
    "order_type",
    "status_name",
    "form_number",
    "job_number",
    "filter_orders",
    "order_active",
  ],
  accessorKey: "form",
  canSort: true,
  orderByField: "order_status",
  width: 130,
  dataTransform: ({ order_type, status_name, form_number, job_number, filter_orders, order_active }) => ({
    order_type,
    status_name,
    form_number,
    href: getOrderHref(job_number, order_type, form_number),
    filter_orders: filter_orders.map(
      fo => ({
        order_type: fo.order_type,
        form_number: fo.form_number,
        href: getOrderHref(job_number, fo.order_type, fo.form_number)
      })
    ),
    order_active: order_active,
  }),
};

const BudgetSubtotalColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Budget/Total",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["budget_subtotal"],
  accessorKey: "budget_subtotal",
  canSort: true,
  orderByField: "budget_subtotal",
  width: 75,
  moneyOptions: { decimalPlaces: 0 },
  dataTransform: ({ budget_subtotal, currency_id }) => ({
    amount: budget_subtotal,
    currency_id,
  }),
};

const InHandsDateColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Date> = {
  header: "In-Hands Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_inhandsdate"],
  accessorKey: "date_inhandsdate",
  canSort: true,
  orderByField: "date_inhandsdate",
  width: 100,
};

const OrderDateColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Date> = {
  header: "Order Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_orderdate"],
  accessorKey: "date_orderdate",
  canSort: true,
  orderByField: "date_orderdate",
  width: 100,
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ClientRepColumn,
  OrderRepColumn,
  ProjectDetailsColumn,
  StatusColumn,
  BudgetSubtotalColumn,
  InHandsDateColumn,
  OrderDateColumn,
];
