import React, { useEffect, useState } from "react";
import { CreatableSelect } from "@commonsku/styles";
import { oauth } from "../../utils";

interface Option {
  value: string;
  label: string;
  id?: string;
  name: string;
  description: string;
  cost: number;
  excludeFromMargin: boolean;
}

type Options = Array<Option>;

function formatCreateLabel(inputValue: string): string {
  return inputValue;
}

function formatOptionLabel(option: Option): React.ReactNode {
  return option.label;
}

function getNewOptionData(inputValue: string, optionLabel: string): Option {
  return {
    value: inputValue.trim(),
    label: optionLabel,
    name: inputValue.trim(),
    description: "",
    cost: 0,
    excludeFromMargin: false
  };
}

function isValidNewOption(inputValue: string, value: Options, options: Options): boolean {
  return (
    inputValue.trim().length > 0 &&
    !options.find(o => o.label === inputValue.trim())
  );
}

function BillItemTypeSelect(
  {
    value,
    onChange,
    disabled,
  }: {
    value: string;
    onChange: (newValue: Option) => void;
    disabled?: boolean;
  }
) {
  const [options, setOptions] = useState([] as Options);
  useEffect(() => {
    async function fetchBillItemTypes() {
      const { json: { bill_item_types } } = await oauth("GET", "bill-item-type", {});
      setOptions(bill_item_types.toSorted(
        (a, b) => a.bill_item_type_name.localeCompare(b.bill_item_type_name)
      ).map(
        bit => ({
          value: bit.bill_item_type_id,
          label: bit.bill_item_type_name.toUpperCase(),
          id: bit.bill_item_type_id,
          name: bit.bill_item_type_name.toUpperCase(),
          description: bit.bill_item_type_description,
          cost: Number(bit.default_cost),
          excludeFromMargin: Boolean(bit.exclude_from_margin)
        })
      ));
    }
    fetchBillItemTypes();
  }, []);
  const selected = options.find(
    o => o.value === value
  ) ?? getNewOptionData(value, value);
  
  return (
    <CreatableSelect
      inPopup
      value={selected}
      options={options}
      onChange={onChange}
      formatCreateLabel={formatCreateLabel}
      formatOptionLabel={formatOptionLabel}
      getNewOptionData={getNewOptionData}
      isValidNewOption={isValidNewOption}
      isDisabled={disabled}
    />
  );
}

export default BillItemTypeSelect;
