import React from "react";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { ClientColumn, SalesOrderNumberColumn, SubtotalColumn, TotalColumn } from "../common";
import { useReportContext } from "../../report_context";
import { Status } from "../../../../constants";

const ORepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Order Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_user_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id"
  ],
  accessorKey: "sales_rep",
  canSort: true,
  orderByField: "sales_rep",
  width: 175,
  avatarOptions: {
    withFullName: true,
  },
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};

const ProjectNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: <>Project&nbsp;#</>,
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number"],
  accessorKey: "job_number",
  canSort: true,
  orderByField: "job_number",
  width: 83,
  dataTransform: ({ job_number }) => ({
    href: `/project/${job_number}`,
    text: job_number,
  }),
};

function DateColumnHeader() {
  const { prevQuery } = useReportContext();
  switch ("status_id" in prevQuery ? prevQuery.status_id : null) {
    case Status.SALES_ORDER_IN_PRODUCTION:
      return "In-Hands Date";
    case Status.SALES_ORDER_SHIPPED:
      return "Date Shipped";
    default:
      return "Order Date";
  }
}

export const ReportDateColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: <DateColumnHeader />,
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_reportdate"],
  accessorKey: "date_reportdate",
  canSort: true,
  orderByField: "date_reportdate",
  width: 120,
};

const ProjectNameColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> =
{
  header: "Project",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_name", "job_number"],
  accessorKey: "job_name",
  canSort: true,
  orderByField: "job_name",
  width: 300,
  textOptions: {
    truncate: true,
 },
  dataTransform: ({ job_name, job_number }) => ({
    href: `/project/${job_number}`,
    text: job_name,
  }),
};

SalesOrderNumberColumn.header = <>Form&nbsp;#</>;
SalesOrderNumberColumn.width =  83;

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ORepColumn,
  SalesOrderNumberColumn,
  ProjectNumberColumn,
  ProjectNameColumn,
  ClientColumn,
  ReportDateColumn,
  SubtotalColumn,
  TotalColumn,
];
