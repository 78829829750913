import React, { CSSProperties, useEffect, useMemo } from "react";
import {
  colors,
  DateRange,
  DateRangeDropdown as BaseDateRangeDropdown,
  DateRangePreset,
} from "@commonsku/styles";
import { format, isValid } from "date-fns";
import { FilterMainComponentProps } from "../Filter";
import styled from "styled-components";

export const dateRangePickerFields = [
  "date_category",
  "start_stamp",
  "end_stamp",
];
export type DateRangePickerValues = [string, string, string];

const dateFormat = "yyyy-MM-dd";

const DEFAULT_PRESETS: DateRangePreset[] = [
  {
    name: "today",
    label: "Today",
  },
  {
    name: "yesterday",
    label: "Yesterday",
  },
  {
    name: "this_week",
    label: "This Week",
  },
  {
    name: "last_week",
    label: "Last Week",
  },
  {
    name: "this_month",
    label: "This Month",
  },
  {
    name: "last_month",
    label: "Last Month",
  },
  {
    name: "this_quarter",
    label: "This Quarter",
  },
  {
    name: "previous_quarter",
    label: "Previous Quarter",
  },
  {
    name: "this_year",
    label: "This Year",
  },
  {
    name: "last_year",
    label: "Last Year",
  },
  {
    name: "last_ytd",
    label: "Last Year To Date",
  },
];

export type DateRangePresetPivots = {
  [key in typeof DEFAULT_PRESETS[number]['name']]: DateRangePreset[];
};

const dateRangeDropdownStyles: CSSProperties = {
  position: "absolute",
  marginTop: "1rem",
  padding: "1rem",
  background: colors.white,
  border: "2px solid",
  borderRadius: "5px",
  borderColor: colors.primary1["60"],
  zIndex: 2,
};

const DateRangeDropdown = styled(BaseDateRangeDropdown)<{ labelLength: number }>`
  &&& {
    input {
      text-overflow: ellipsis;
      white-space: nowrap;
      padding-right: 32px;
    }
  }
`;

export interface DateRangePickerProps extends FilterMainComponentProps {
  values: DateRangePickerValues;
  onChange: (values: DateRangePickerValues) => void;
  placeholder: string;
}

const DateRangePicker = ({
  values,
  onChange,
  dependsOnValues = "",
  placeholder = "ALL",
  presets = DEFAULT_PRESETS,
  presetPivots,
}: DateRangePickerProps) => {
  const presetOptions = useMemo(() => {  
    if (presetPivots == null || !Array.isArray(dependsOnValues) || dependsOnValues.length === 0) {
      return presets;
    }

    const pivotCategory = dependsOnValues[0] as keyof DateRangePresetPivots;

    if (!(pivotCategory in presetPivots)) {
      return presets;
    }

    return presetPivots[pivotCategory];
  }, [dependsOnValues, presets, presetPivots]);

  const presetsToUse = useMemo(() => {
    const category = values[0];

    if (category === "" || presetPivots == null) {
      return presetOptions;
    }

    const selectedPreset = Object.values(presetPivots)
      .flat()
      .find((preset) => preset.name === category);

    if (selectedPreset == null || presetOptions.includes(selectedPreset)) {
      return presetOptions;
    }

    return [selectedPreset, ...presetOptions];
  }, [presetOptions, values, presetPivots]);

  useEffect(() => {
    // If pivot presets are available and no custom range is selected, default to the first pivot preset
    const [category, start, end] = values;

    if (presetOptions !== presets && category !== presetOptions[0].name && start === "" && end === "") {
      onChange([
        presetOptions[0].name,
        "",
        "",
      ]);
    }
  }, [presetOptions]);

  const dateRange = useMemo(() => {
    let [category, start, end] = values;
    // Add time to start and end date, otherwise the date will be off by a day
    start += "T00:00:00";
    end += "T23:59:59";
    const startDate = isValid(new Date(start)) ? new Date(start) : null;
    const endDate = isValid(new Date(end)) ? new Date(end) : null;

    return {
      category,
      startDate,
      endDate,
    };
  }, [values]);

  const dependentRangeIsCustom = useMemo(() => {
    if (!Array.isArray(dependsOnValues) || dependsOnValues.length !== 3) {
      return false;
    }

    const [_, start, end] = dependsOnValues;

    return start !== "" || end !== "";
  }, [dependsOnValues]);

  const getDateString = (date?: Date | null) =>
    date != null ? format(date, dateFormat) : "";

  return (
    <DateRangeDropdown
      range={dateRange}
      placeholder={placeholder}
      presets={presetsToUse}
      onChange={(range: DateRange) =>
        onChange([
          range.category,
          getDateString(range.startDate),
          getDateString(range.endDate),
        ])
      }
      isClearable
      style={dateRangeDropdownStyles}
      initialActiveTab={dependentRangeIsCustom ? "custom" : "preset"}
    />
  );
};

export default DateRangePicker;
export const DATE_RANGE_PRESETS = DEFAULT_PRESETS;
