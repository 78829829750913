import React from "react";
import { useSelector } from "react-redux";
import { getZeroTaxOptions } from "../../selectors/dropdowns";
import LabeledSelect from "../Select";

const ExemptTaxSelect = ({
  value,
  label,
  onChange,
  labelStyle,
  isDisabled = false,
}: {
  value?: string;
  label?: string;
  onChange?: (value: string) => void;
  labelStyle?: React.CSSProperties;
  isDisabled?: boolean;
}) => {
  const options = useSelector(getZeroTaxOptions).map((o) => ({
    label: o.value,
    value: o.key,
  }));

  return (
    <LabeledSelect
      label={label}
      value={value}
      // @ts-ignore
      onChange={(e) => onChange && onChange(e.value)}
      // @ts-ignore
      options={options}
      labelStyle={labelStyle}
      isDisabled={isDisabled}
      inPopup
    />
  );
};

export default ExemptTaxSelect;
