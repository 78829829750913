import React from "react";
import { Col, Loading, Row } from "@commonsku/styles";

import { VendorCredit } from "../../types/vendor_credit";

import FormPopup from "../FormPopup";
import SupplierVendorCreditPopup from "./SupplierVendorCreditPopup";
import BillVendorCreditPopup from "./BillVendorCreditPopup";

const LOADING_VENDOR_CREDIT_POPUP_WIDTH = "640px";
const LOADING_VENDOR_CREDIT_POPUP_HEIGHT = "324px";

export default function VendorCreditPopup(
  {
    vendorCredit,
    onClose,
  }: {
    vendorCredit?: VendorCredit,
    onClose: () => void,
  }
) {
  switch (vendorCredit?.type) {
    case "SUPPLIER":
      return (
        <SupplierVendorCreditPopup vendorCredit={vendorCredit} onClose={onClose} />
      );
    case "BILL":
      return (
        <BillVendorCreditPopup vendorCredit={vendorCredit} onClose={onClose} />
      );
    default:
      return (
        <FormPopup
          title="Vendor Credit"
          onClose={onClose}
          isNew={false}
          isValid={false}
          isEditable={false}
          width={LOADING_VENDOR_CREDIT_POPUP_WIDTH}
          height={LOADING_VENDOR_CREDIT_POPUP_HEIGHT}
        >
          <Row>
            <Col>
              <Loading />
            </Col>
          </Row>
        </FormPopup>
      );
  }
}
