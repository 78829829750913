import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { BulkActions } from "./bulk-actions";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const FinanceVendorCreditsReportConfig: ReportConfigBase = {
  type: EReportTypes.FinanceVendorCredits,
  endpoint: "vendor-credit",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: true,
  intro: {
    description:
      "Vendor Credits",
    // TODO: Need a help article once this is ready
    url: "https://help.commonsku.com/knowledge/",
  },
  bulkActions: BulkActions,
};

export default FinanceVendorCreditsReportConfig;
