import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { colors, CheckmarkIcon, EllipsisIcon, fontFamilies } from "@commonsku/styles"
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import {
  ClientColumn,
  OrderDateColumn,
  InvoiceNumberColumn,
  SubtotalColumn,
  TotalColumn,
} from "../common";
import { getDefaultInvoiceCreditMemo } from "../../../../redux/credit_memos";
import { formatDateFromString, formatMoneyCurrency } from "../../../../utils";
import DropdownMenu, { MenuTrigger } from "../../../DropdownMenu";
import { CreditMemo } from "../../../../types/credit_memo";
import CreditMemoPopup from "../../../credit-memo/CreditMemoPopup";

const ORepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: <>Order&nbsp;Rep</>,
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_user_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id"
  ],
  accessorKey: "sales_rep",
  canSort: true,
  orderByField: "sales_rep",
  width: 150,
  avatarOptions: {
    withFullName: true,
  },
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};

const ProjectNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: <>Project&nbsp;#</>,
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number"],
  accessorKey: "job_number",
  canSort: true,
  orderByField: "job_number",
  width: 83,
  dataTransform: ({ job_number }) => ({
    href: `/project/${job_number}`,
    text: job_number,
  }),
};

const ProjectNameColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> =
{
  header: "Project",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_name", "job_number"],
  accessorKey: "job_name",
  canSort: true,
  orderByField: "job_name",
  width: 150,
 textOptions: {
    truncate: true,
 },
  dataTransform: ({ job_name, job_number }) => ({
    href: `/project/${job_number}`,
    text: job_name,
  }),
};

const StatusColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Generic> =
{
  header: "Status",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["status_name", "credit_memos"],
  accessorKey: "status_name",
  canSort: true,
  orderByField: "status_id",
  width: 83,
  textOptions: {
     truncate: true,
  },
  dataTransform: ({ status_name, credit_memos }) => ({
    cell: <span>
      {status_name}
      {credit_memos.length > 0 ?
         <span style={{ color: colors.green.main }}>&nbsp;CM</span> :
         null}
    </span>,
    tooltipText: credit_memos.length > 0 ?
      <table>
        <tbody>
          {credit_memos.map(
            cm => (
              <tr key={cm.credit_memo_id}>
                <td style={{ padding: "0.5rem" }}>#{cm.form_number}</td>
                <td style={{ padding: "0.5rem" }}>{formatMoneyCurrency(cm.total_total, cm.currency_id)}</td>
                <td style={{ padding: "0.5rem" }}>
                  {!!cm.date_exported ?
                    <>
                      <CheckmarkIcon color={colors.green.main} />
                      {formatDateFromString(
                        cm.date_exported,
                        {
                          locales: "en-US",
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          emptyValue: ""
                       }
                      )}
                    </> : null}
                </td>
              </tr>
            )
          )}
        </tbody>
      </table> : null
  }),
};

const StyledDropdownMenu = styled(DropdownMenu)`
& > .dropdown-menu-trigger {
  color: ${colors.primary1.main};
  border-radius: 5px;
  padding: 4px 4px 0 4px;
}
&.dropdown-menu-open > .dropdown-menu-trigger {
  border: 2px solid ${colors.primary1.main};
}
&&& > ul {
  border: 2px solid ${colors.primary1.main};
  border-radius: 5px;
  margin-top: 4px;
  right: 1rem;
  min-width: 200px;

  & > li {
    font-family: ${fontFamilies.regular};
    color: ${colors.neutrals.bodyText};
  }
}
`;

function InvoiceActions({ order_id }: { order_id: string }) {
  const [creditMemo, setCreditMemo] = useState<CreditMemo | undefined>();
  const options = useMemo(
    () => [{
      value: "Add Credit Memo",
      hideOnClick: true,
      onClick: async () => {
        const creditMemo = await getDefaultInvoiceCreditMemo(order_id);
        setCreditMemo(creditMemo);
      }
    }],
    [order_id]
  );
  const onClose = useCallback(
    () => {
      setCreditMemo(undefined);
    },
    []
  );
  return (
    <>
      {creditMemo && <CreditMemoPopup creditMemo={creditMemo} onClose={onClose} />}
      <StyledDropdownMenu options={options} align="right">
       <MenuTrigger><EllipsisIcon altText="Actions" /></MenuTrigger>
      </StyledDropdownMenu>
    </>
  );
}

const ActionColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Generic> =
{
  header: "Actions",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["order_id"],
  accessorKey: "order_id",
  canSort: false,
  width: 120,
  justify: "center",
  dataTransform: ({ order_id }) => ({
    cell: <InvoiceActions order_id={order_id} />
  })
};

const InvoiceDateColumn = {
  ...OrderDateColumn,
  header: <>Invoice&nbsp;Date</>,
};

InvoiceNumberColumn.header = <>Form&nbsp;#</>;
InvoiceNumberColumn.width =  83;

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ORepColumn,
  InvoiceNumberColumn,
  ProjectNumberColumn,
  ProjectNameColumn,
  StatusColumn,
  ClientColumn,
  InvoiceDateColumn,
  SubtotalColumn,
  TotalColumn,
  ActionColumn,
];
