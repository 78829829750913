import { useMemo } from "react";
import { useSelector } from "react-redux";

import { useIdentity } from "./index";
import { getZeroTaxOptions } from "../selectors/dropdowns";

/**
 * Custom hook that provides Canadian QBO tax information
 * @returns {Object} Object containing isCanadaQBO flag and defaultZeroTaxId
 */
export const useCanadianTax = () => {
  const identity = useIdentity();
  const isCanadaQBO = identity?.qbo_country === "CA";
  const zeroTaxOptions = useSelector(getZeroTaxOptions);
  const defaultZeroTaxId = useMemo(
    () =>
      isCanadaQBO && zeroTaxOptions.length > 0 ? zeroTaxOptions[0]?.key : "",
    [isCanadaQBO, zeroTaxOptions],
  );

  return {
    isCanadaQBO,
    defaultZeroTaxId,
  };
};

export default useCanadianTax;
