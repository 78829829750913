import React from "react";
import styled from "styled-components";
import { Button, Popup } from "@commonsku/styles";
import { PopupHeaderWithCloseIcon } from "./report/action-menu/LoadReportPopup";

const FormPopupActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const FormPopupForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  overflow-y: auto;
`;

const FORM_POPUP_STYLE = {
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  gap: "1rem",
};

const FORM_POPUP_CONTENT_STYLE = {
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "space-between",
  flexGrow: 1,
  gap: "1rem",
  overflowY: "hidden",
} as React.CSSProperties;

interface FormPopupProps {
  isNew: boolean;
  isEditable: boolean;
  isValid: boolean;
  onClose: () => void;
  onSave?: () => Promise<void>;
  onError?: (error: unknown) => void;
  title: React.ReactNode;
  width?: string;
  height?: string;
  children: React.ReactNode;
  customActions?: React.ReactNode;
  zIndex?: number;
  overlayZIndex?: number;
  onLeftArrowClick?: () => void;
}

export default function FormPopup({
  isNew,
  isEditable,
  isValid,
  onClose,
  onSave,
  onError,
  title,
  width,
  height,
  children,
  customActions = null,
  onLeftArrowClick = null,
  ...zIndexProps
}: FormPopupProps) {
  const handleSave = async () => {
    if (onSave) {
      try {
        await onSave();
        onClose();
      } catch (e) {
        if (onError) {
          onError(e);
        }
      }
    }
  };

  const renderActions = () => {
    if (customActions) {
      return customActions;
    }
    return (
      <>
        {(isEditable || !onSave) && (
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
        )}
        {!!onSave &&
          (isEditable ? (
            <Button disabled={!isValid} onClick={handleSave}>
              {isNew ? "Create" : "Save"}
            </Button>
          ) : (
            <Button onClick={onClose}>Close</Button>
          ))}
      </>
    );
  };

  return (
    <Popup
      closeOnEsc
      closeOnClickOutside={false}
      onClose={onClose}
      width={width}
      height={height}
      style={FORM_POPUP_STYLE}
      popupContentStyle={FORM_POPUP_CONTENT_STYLE}
      header={
        <PopupHeaderWithCloseIcon
          title={title}
          onPopupClose={onClose}
          onLeftArrowClick={onLeftArrowClick}
        />
      }
      {...zIndexProps}
    >
      <FormPopupForm>{children}</FormPopupForm>
      <FormPopupActions>{renderActions()}</FormPopupActions>
    </Popup>
  );
}
