import React from "react";
import { colors, CheckmarkIcon, XIcon } from "@commonsku/styles";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { SupplierColumn } from "../common";
import { formatDateFromString } from "../../../../utils";

const VendorCreditNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Text
> = {
  header: "Vendor Credit",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["vendor_credit_id", "form_number"],
  accessorKey: "form_number",
  canSort: true,
  orderByField: "form_number",
  width: 83,
  dataTransform: ({ form_number }) => ({
    text: form_number,
  }),
};

export const BillNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Text
> = {
  header: "Bill #",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["bill_reference_number"],
  accessorKey: "bill_reference_number",
  canSort: true,
  orderByField: "bill_reference_number",
  width: 83,
  dataTransform: ({ bill_reference_number }) => ({
    text: bill_reference_number ?? <span>&mdash;</span>,
    tooltipText: bill_reference_number,
  }),
};

export const PurchaseOrdersColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Generic
> = {
  header: "PO #",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["purchase_orders"],
  accessorKey: "purchase_orders",
  canSort: true,
  orderByField: "po_form_number",
  width: 95,
  dataTransform: ({ purchase_orders }) => ({
    cell: <>
      {purchase_orders.length === 0 ? <span>&mdash;</span> : purchase_orders.map(
        po => (
          <a key={po.purchase_order_id} target="_blank" href={`/project/${po.job_number}/production`}>{po.form_number}</a>
        )
      )}
    </>,
    tooltipText: purchase_orders.map(po => po.form_number).join(", ")
  })
};

export const ProjectsColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Generic
> = {
  header: "Project",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["jobs"],
  accessorKey: "jobs",
  canSort: true,
  orderByField: "job_name",
  width: 95,
  dataTransform: ({ jobs }) => ({
    cell: <>
      {jobs.length === 0 ? <span>&mdash;</span> : (jobs.length === 1 ?
        <a key={jobs[0].job_id} target="_blank" href={`/project/${jobs[0].job_number}`}>{jobs[0].job_name}</a> :
        <span>{jobs.length} projects</span>
      )}
    </>,
    tooltipText: <>
      {jobs.map(j => (
        <div key={j.job_id}>
          <a target="_blank" href={`/project ${j.job_number}`}>#{j.job_number}: {j.job_name}</a>
        </div>
      ))}
    </>
  })
};

export const RefundAmountColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Refund Amount",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_total", "currency_id"],
  accessorKey: "total",
  canSort: true,
  orderByField: "total_total",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ total_total, currency_id }) => ({
    amount: total_total,
    currency_id,
  }),
};

export const DateIssuedColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Date Issued",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_issued"],
  accessorKey: "date_issued",
  canSort: true,
  orderByField: "date_issued",
  width: 120,
  dateOptions: { locales: "en-US", day: "numeric", month: "short", year: "numeric" },
};

export const ExportedToQuickbooksColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Generic
> = {
  header: "Exported to QuickBooks",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["vendor_credit_id", "date_exported"],
  accessorKey: "date_exported",
  canSort: true,
  orderByField: "date_exported",
  width: 83,
  dataTransform: ({ date_exported }) => {
    if (!date_exported) {
      return {
        cell: <XIcon color={colors.neutrals["60"]} />
      }
    }
    return {
      cell: (
        <>
          <CheckmarkIcon color={colors.green.main} />
          {formatDateFromString(
            date_exported,
            {
              locales: "en-US",
              day: "numeric",
              month: "short",
              year: "numeric",
              emptyValue: ""
           }
          )}
        </>
      )
    };
  },
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ProjectsColumn,
  BillNumberColumn,
  SupplierColumn,
  PurchaseOrdersColumn,
  VendorCreditNumberColumn,
  RefundAmountColumn,
  DateIssuedColumn,
  ExportedToQuickbooksColumn,
];
