import React from "react";
import { BulkActionConfigBase } from "../index";
import {
  prepareSelectBillsLabel as prepareLabel,
  DeleteBillsButton,
  createExportToAccountingButton
} from "../common/bulk-actions";

const ExportToAccountingButton = createExportToAccountingButton(
  "BILL",
  (row: any) => row.bill_reference_number.bill_id
);

export const BulkActions: BulkActionConfigBase = {
  prepareLabel,
  actions: [
    DeleteBillsButton,
    ExportToAccountingButton,
  ]
};
