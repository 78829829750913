import React from "react";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { ClientColumn, OrderDateColumn } from "../common";

const ORepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: <>Order&nbsp;Rep</>,
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_user_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id"
  ],
  accessorKey: "sales_rep",
  canSort: true,
  orderByField: "sales_rep",
  width: 150,
  avatarOptions: {
    withFullName: true,
  },
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};

export const SalesOrderNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: <>SO&nbsp;#</>,
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number", "order_form_number"],
  accessorKey: "sales_order_number",
  canSort: true,
  orderByField: "order_form_number",
  width: 83,
  dataTransform: ({ job_number, order_form_number }) => ({
    href: `/project/${job_number}/sales-order/${order_form_number}`,
    text: order_form_number,
  }),
};

export const DepositNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Text
> = {
  header: <>Deposit&nbsp;#</>,
  type: BASE_COLUMN_TYPES.Text,
  fields: ["form_number"],
  accessorKey: "form_number",
  canSort: true,
  orderByField: "form_number",
  width: 83,
};


export const ConfirmationNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Text
> = {
  header: <>Confirmation&nbsp;#</>,
  type: BASE_COLUMN_TYPES.Text,
  fields: ["deposit_confirmation"],
  accessorKey: "deposit_confirmation",
  canSort: true,
  textOptions: {
    truncate: true,
  },
  orderByField: "deposit_confirmation",
  width: 120,
};

const ProjectNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: <>Project&nbsp;#</>,
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number"],
  accessorKey: "job_number",
  canSort: true,
  orderByField: "job_number",
  width: 83,
  dataTransform: ({ job_number }) => ({
    href: `/project/${job_number}`,
    text: job_number,
  }),
};

const ProjectNameColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> =
{
  header: "Project",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_name", "job_number"],
  accessorKey: "job_name",
  canSort: true,
  orderByField: "job_name",
  width: 150,
  textOptions: {
    truncate: true,
  },
  dataTransform: ({ job_name, job_number }) => ({
    href: `/project/${job_number}`,
    text: job_name,
  }),
};

export const DatePaidColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: <>Date&nbsp;Paid</>,
  type: BASE_COLUMN_TYPES.Date,
  fields: ["deposit_received"],
  accessorKey: "deposit_received",
  canSort: true,
  orderByField: "deposit_received",
  width: 120,
};

export const DepositAmountColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: <>Deposit&nbsp;Amount</>,
  type: BASE_COLUMN_TYPES.Money,
  fields: ["deposit_amount", "currency_id"],
  accessorKey: "deposit_amount",
  canSort: true,
  orderByField: "deposit_amount",
  width: 150,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ deposit_amount, currency_id }) => ({
    amount: deposit_amount,
    currency_id,
  }),
};

export const AmountPaidColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: <>Amount&nbsp;Received</>,
  type: BASE_COLUMN_TYPES.Money,
  fields: ["deposit_amount_received", "currency_id"],
  accessorKey: "deposit_amount_received",
  canSort: true,
  orderByField: "deposit_amount_received",
  width: 150,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ deposit_amount_received, currency_id }) => ({
    amount: deposit_amount_received,
    currency_id,
  }),
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ORepColumn,
  SalesOrderNumberColumn,
  DepositNumberColumn,
  ConfirmationNumberColumn,
  ProjectNumberColumn,
  ProjectNameColumn,
  ClientColumn,
  OrderDateColumn,
  DatePaidColumn,
  DepositAmountColumn,
  AmountPaidColumn,
];
