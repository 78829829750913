import { DateRangePreset } from "@commonsku/styles";
import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";
import { DateRangePresetPivots } from "../../filters/components/DateRangePicker";
  
const BASE_DATE_RANGE_PRESETS: DateRangePreset[] = [
  {
    name: "this_month",
    label: "This Month",
  },
  {
    name: "this_quarter",
    label: "This Quarter",
  },
  {
    name: "this_year",
    label: "This Year",
  },
];

const PIVOT_DATE_RANGE_PRESETS: DateRangePresetPivots = {
  this_month: [
    {
      name: "last_month",
      label: "Last month (full)",
    },
    {
      name: "last_month_to_date",
      label: "Last month (to date)",
    },
    {
      name: "this_month_last_year",
      label: "This month last year (full)",
    },
    {
      name: "this_month_last_year_to_date",
      label: "This month last year (to date)",
    },
  ],
  this_quarter: [
    {
      name: "previous_quarter",
      label: "Last quarter (full)",
    },
    {
      name: "previous_quarter_to_date",
      label: "Last quarter (to date)",
    },
    {
      name: "this_quarter_last_year",
      label: "This quarter last year (full)",
    },
    {
      name: "this_quarter_last_year_to_date",
      label: "This quarter last year (to date)",
    },
  ],
  this_year: [
    {
      name: "last_year",
      label: "Last year (full)",
    },
    {
      name: "last_ytd",
      label: "Last year (to date)",
    },
  ],
};

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Client",
    type: FILTER_TYPES.Client,
    fields: "client_ids",
    flexBasis: 240,
    isMulti: true,
  },
  {
    label: "Range 1",
    type: FILTER_TYPES.DateRange,
    fields: ["category_date_range_1", "start_date_range_1", "end_date_range_1"],
    defaultValue: ["", "", ""],
    presets: BASE_DATE_RANGE_PRESETS,
  },
  {
    label: "Range 2",
    type: FILTER_TYPES.DateRange,
    fields: ["category_date_range_2", "start_date_range_2", "end_date_range_2"],
    defaultValue: ["", "", ""],
    dependsOn: { filterFields: ["category_date_range_1", "start_date_range_1", "end_date_range_1"] },
    presets: BASE_DATE_RANGE_PRESETS,
    presetPivots: PIVOT_DATE_RANGE_PRESETS,
  },
  {
    label: "Client Tag",
    type: FILTER_TYPES.ClientTag,
    fields: "client_tags",
  },
  {
    label: "Client Status",
    type: FILTER_TYPES.AccountStatus,
    fields: "account_status_id",
    dropdownOptionsFilter: {
      company_type: "CLIENT",
    }
  },
];
