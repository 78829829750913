import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { colors, ConfirmPopup, EllipsisIcon, fontFamilies, Typography } from "@commonsku/styles";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { SupplierColumn } from "../common";
import { useReportContext } from "../../report_context";
import { oauth } from "../../../../utils";
import { useHasCapabilities, useIdentity } from "../../../../hooks";
import { deleteBill, retrieveBill } from "../../../../redux/bills";
import DropdownMenu, { MenuTrigger } from "../../../DropdownMenu";
import { Bill } from "../../../../types/bills";
import BillPopup from "../../../bills/BillPopup";

function BillReferenceNumberCell({ bill_id, bill_reference_number }) {
  const [bill, setBill] = useState<Bill | undefined>();
  const dispatch = useDispatch();

  const handleClick = useCallback(async () => {
    const bill = await retrieveBill(bill_id)(dispatch);
    setBill(bill);
  }, [bill_id, dispatch]);

  const handleClose = useCallback(
    () => setBill(undefined),
    []
  );
  
  return (
    <>
      {bill && <BillPopup bill={bill} onClose={handleClose} />}
      <Typography.Span
        as="a"
        style={{
          display: "block",
          alignContent: "center",
          color: colors.primary1.main,
        }}
        title={bill_reference_number}
        onClick={handleClick}
      >
        {bill_reference_number}
      </Typography.Span>
    </>
  );
}

export const BillReferenceNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Generic
> = {
  header: "Invoice #",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["bill_id", "bill_reference_number"],
  accessorKey: "bill_reference_number",
  canSort: true,
  orderByField: "bill_reference_number",
  width: 83,
  dataTransform: ({ bill_id, bill_reference_number }) => ({
    cell: <BillReferenceNumberCell bill_id={bill_id} bill_reference_number={bill_reference_number} />
  }),
};

export const BillPurchaseOrdersColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Purchase Orders",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["purchase_orders", "division_id"],
  accessorKey: "purchase_orders",
  canSort: false,
  width: 105,
  dataTransform: ({ purchase_orders, division_id }) => ({
    text: purchase_orders.map(po => po.form_number).join(", "),
    href: purchase_orders.length === 1 ? `/purchase_orders.php?id=${purchase_orders[0].purchase_order_id}` : null,
    tooltipText: (
      <div>
        <p>Purchase Orders in this bill:</p>
        {purchase_orders.map((po, index) => (
          <React.Fragment key={po.purchase_order_id}>
            <Typography.Span
              as="a"
              style={{
                display: "block",
                alignContent: "left",
                color: colors.primary1.main,
              }}
              href={`/purchase_order.php?id=${po.purchase_order_id}`}
              target="_blank"
              rel="noreferrer"
              title={po.po_name}
              onClick={(e) => { console.log(po.purchase_order_id); e.stopPropagation();}}
            >
              #{po.form_number} {division_id !== po.division_id && <>({po.division_name})</>}
            </Typography.Span>
          </React.Fragment>
        ))}
      </div>
    ),
    tooltipOptions: { effect: "solid", place: "right" }
  })
};


export const BillDateColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Bill Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["bill_date_billdate"],
  accessorKey: "bill_date_billdate",
  canSort: true,
  orderByField: "bill_date_billdate",
  width: 120,
};

export const DueDateColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Due Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["bill_date_due"],
  accessorKey: "bill_date_due",
  canSort: true,
  orderByField: "bill_date_due",
  width: 120,
};

export const AmountDueColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Amount Due",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["bill_amount_due", "currency_id"],
  accessorKey: "bill_amount_due",
  canSort: true,
  orderByField: "bill_amount_due",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ bill_amount_due, currency_id }) => ({
    amount: bill_amount_due,
    currency_id,
  }),
};

export const ExportDateColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Export Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_exported"],
  accessorKey: "date_exported",
  canSort: true,
  orderByField: "date_exported",
  width: 120,
};

const StyledDropdownMenu = styled(DropdownMenu)`
& > .dropdown-menu-trigger {
  color: ${colors.primary1.main};
  border-radius: 5px;
  padding: 4px 4px 0 4px;
}
&.dropdown-menu-open > .dropdown-menu-trigger {
  border: 2px solid ${colors.primary1.main};
}
&&& > ul {
  border: 2px solid ${colors.primary1.main};
  border-radius: 5px;
  margin-top: 4px;
  right: 1rem;
  min-width: 200px;

  & > li {
    font-family: ${fontFamilies.regular};
    color: ${colors.neutrals.bodyText};
  }
}
`;

function BillActions({ bill_id }: { bill_id: string }) {
  const identity = useIdentity();
  const canDeleteBill = useHasCapabilities(["DELETE-BILL"]);
  const canExport = useHasCapabilities(["EXPORT-REPORT"]);
  const canEditBill = useHasCapabilities(["UPDATE-BILL"]);
  const { fetchReport } = useReportContext();
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();

  const bill_ids = useMemo(
    () => [bill_id],
    [bill_id]
  );

  const handleUnExport = useCallback(async () => {
    try {
      await oauth('POST', 'bill', { action: 'unexport', ids: bill_ids });
      fetchReport();
    } catch (e) {
      toast.error("Unable to un-export bill");
    }
  }, [bill_ids]);

  const handleDelete = useCallback(async () => {
    try {
      await dispatch(deleteBill(bill_id));
      fetchReport();
    } catch (e) {
      toast.error("Unable to delete bill");
    } finally {
      setShowConfirm(false);
    }
  }, [bill_id]);

  const options = useMemo(
    () => [
      {
        value: "Un-Export",
        hideOnClick: true,
        onClick: handleUnExport,
        show: canExport,
      },
      {
        value: "Edit",
        hideOnClick: true,
        onClick: async () => {
          const bill = await dispatch(retrieveBill(bill_id));
          console.log(bill);
        },
        show: canEditBill,
      },
      {
        value: <span style={{ color: colors.error }}>Delete</span>,
        hideOnClick: true,
        onClick: () => setShowConfirm(true),
        show: canDeleteBill,
      }
    ].filter(
      o => o.show
    ),
    [bill_id]
  );
  return (
    <>
      {showConfirm && (
        <ConfirmPopup
          confirmText="Are you sure you want to delete?  Unpaid commissions will be recalculated."
          onClose={() => setShowConfirm(false)}
          onDelete={handleDelete}
        />
      )}
      <StyledDropdownMenu options={options} align="right">
       <MenuTrigger><EllipsisIcon altText="Actions" /></MenuTrigger>
      </StyledDropdownMenu>
    </>
  );
}

const ActionColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Generic> =
{
  header: "Actions",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["bill_id"],
  accessorKey: "bill_id",
  canSort: false,
  width: 120,
  justify: "center",
  dataTransform: ({ bill_id }) => ({
    cell: <BillActions bill_id={bill_id} />
  })
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  BillReferenceNumberColumn,
  BillPurchaseOrdersColumn,
  SupplierColumn,
  BillDateColumn,
  DueDateColumn,
  AmountDueColumn,
  ExportDateColumn,
  ActionColumn,
];
