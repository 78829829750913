import React from "react";
import { BulkActionConfigBase } from "../index";
import { createExportToAccountingButton } from "../common/bulk-actions";

export function prepareLabel<
  T extends Record<string, unknown> = Record<string, unknown>
>(selected: T[]): React.ReactNode {
  if (selected.length === 0) {
    return "Select one or more invoices";
  }
  if (selected.length === 1) {
    return "1 invoice selected";
  }
  return `${selected.length} invoices selected`;
}

const ExportToAccountingButton = createExportToAccountingButton(
  "INVOICE",
  (row: any) => row.invoice_number.order_id
);

export const BulkActions: BulkActionConfigBase = {
  prepareLabel,
  actions: [
    ExportToAccountingButton,
  ]
};
