import { EReportTypes } from "../../routes/report_types";
import {
  SubReportConfigBase,
  SubReportFilter,
  SubReportFilterSources,
} from "../../sub-report/types";

export const projects: SubReportConfigBase = {
  type: EReportTypes.ProductsSoldProject,
  tabTitle: "Detailed Projects",
  defaultFilters: [
    {
      field: "order_ids",
      source: {
        type: SubReportFilterSources.Row,
        columnName: "order_count",
      },
    },
  ],
};