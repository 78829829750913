import React from "react";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { formatDateFromString } from "../../../../utils";
import { useReportContext } from "../../report_context";
import { ClientColumn } from "../common";

const SUBHEADER_STYLE = {
  fontWeight: "normal",
  fontSize: "12px",
  fontFamily: "skufont-regular, sans-serif"
};

const DateColumnHeader = ({ name }) => {
  const { summaryData } = useReportContext();
  const summary = summaryData.find(d => d.label === name);
  if (!summary) {
    return name;
  }

  const startDate = formatDateFromString(summary.data[0], { year: "numeric", month: "short", day: "numeric", locales: "en-US" });
  const endDate = formatDateFromString(summary.data[1], { year: "numeric", month: "short", day: "numeric", locales: "en-US" });

  const subheader = startDate && endDate 
    ? `${startDate} - ${endDate}`
    : startDate
      ? "Since " + startDate
      : endDate
        ? "Until " + endDate
        : "All";

  return (
    <div title={name}>
      <div>Invoices</div>
      <div style={SUBHEADER_STYLE}>
        {subheader}
      </div>
    </div>
  );
};

const CurrentColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: <DateColumnHeader name="Current Period Invoices" />,
  type: BASE_COLUMN_TYPES.Money,
  fields: ["fromPeriod"],
  accessorKey: "fromPeriod",
  canSort: true,
  orderByField: "fromPeriod",
  width: 303,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ fromPeriod }) => ({
    amount: fromPeriod,
    currency_id: 'USD',
  }),
};

const PreviousColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: <DateColumnHeader name="Previous Period Invoices" />,
  type: BASE_COLUMN_TYPES.Money,
  fields: ["toPeriod"],
  accessorKey: "toPeriod",
  canSort: true,
  orderByField: "toPeriod",
  width: 303,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ toPeriod }) => ({
    amount: toPeriod,
    currency_id: 'USD',
  }),
};

const ChangeColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Change",
  type: BASE_COLUMN_TYPES.Money,
  moneyOptions: { colorful: true, decimalPlaces: 2 },
  fields: ["fromPeriod", "toPeriod"],
  accessorKey: "change",
  canSort: true,
  orderByField: "change",
  width: 150,
  dataTransform: ({ fromPeriod, toPeriod }) => {
    const change = parseFloat(fromPeriod) - parseFloat(toPeriod);
    return {
      amount: String(change),
      currency_id: 'USD',
    };
  }
};

const ChangePercentageColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Percentage
> = {
  header: "Change %",
  type: BASE_COLUMN_TYPES.Percentage,
  percentageOptions: { includeTrendline: true, decimalPlaces: 2 },
  fields: ["fromPeriod", "toPeriod"],
  accessorKey: "change_percentage",
  canSort: false,
  orderByField: "change_percentage",
  width: 150,
  dataTransform: ({ fromPeriod, toPeriod }) => {
    const change = parseFloat(fromPeriod) - parseFloat(toPeriod);
    let percentage = Math.round(change / parseFloat(toPeriod) * 100);
    if (0 !== parseFloat(toPeriod)) {
      percentage = parseFloat(percentage.toFixed(2));
    }
    if (isNaN(percentage)) {
      percentage = Infinity;
    }
    if (0 === change) {
      percentage = 0;
    }
    return {
      percentage: percentage
    };
  }
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ClientColumn,
  CurrentColumn,
  PreviousColumn,
  ChangeColumn,
  ChangePercentageColumn,
];
