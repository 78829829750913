import {
  createEntityAdapter,
  createSlice
} from "@reduxjs/toolkit";
import { oauth } from "../utils";
import {
  VendorCredit,
  SupplierVendorCredit,
  BillVendorCredit,
} from "../types/vendor_credit";

const adapter = createEntityAdapter({
  selectId: (vendorCredit: VendorCredit) => vendorCredit.id,
});

interface VendorCreditState {
  loading: "idle" | "pending";
}

const initialState = {
  loading: "idle",
};

const slice = createSlice({
  name: "vendorCredits",
  initialState: adapter.getInitialState(initialState),
  reducers: {
    loading(state) {
      state.loading = "pending";
    },
    created(state, action) {
      adapter.addOne(state, action.payload);
      state.loading = "idle";
    },
    retrieved(state, action) {
      adapter.setOne(state, action.payload);
      state.loading = "idle";
    },
    updated(state, action) {
      adapter.updateOne(state, action.payload);
      state.loading = "idle";
    },
    deleted(state, action) {
      adapter.removeOne(state, action.payload);
      state.loading = "idle";
    },
  }
});

export const actions = slice.actions;

function adaptVendorCredit(data: any): VendorCredit {
  const projectsByOrderId = !!data.projects ? data.projects.reduce(
    (projects, p) => ({
      ...projects,
      [p.order_id]: {
        id: String(p.job_id),
        number: Number(p.job_number),
        name: String(p.job_name),
        active: Boolean(p.active)
      }
    }),
    {}
  ) : {};

  const items = data?.items?.reduce(
    (o, i: any) => {
      let group = o[i.purchase_order_id ?? i.order_id] ?? [];
      if (i.item_id) {
        let item = group.find(
          ii => (
            ii.purchaseOrderId === i.purchase_order_id && ii.itemId === i.item_id
          )
        );
        if (!item) {
          item = {
            purchaseOrderId: String(i.purchase_order_id),
            itemId: String(i.item_id),
            code: String(i.item_code),
            name: String(i.item_name),
            breakdowns: [],
            costs: [],
            taxes: i.taxes.map(
              ta => ({
                id: !!ta.tax_amount_id ? String(ta.tax_amount_id) : undefined,
                taxId: String(ta.taxId),
                label: String(ta.label),
                percent: Number(ta.percent),
              })
            )
          };
          group.push(item);
        }
	//TODO: How to distinguish costs?
        if (false) {
          item.costs.push({
            id: !!i.vendor_credit_item_id ? String(i.vendor_credit_item_id) : undefined,
            billItemId: String(i.bill_item_id),
            description: String(i.description),
            billedQuantity: Number(i.billed_quantity),
            quantity: Number(i.quantity),
            unitCost: Number(i.unitCost),
          });
        } else {
          item.breakdowns.push({
            id: !!i.vendor_credit_item_id ? String(i.vendor_credit_item_id) : undefined,
            billItemId: String(i.bill_item_id),
            description: String(i.description),
            billedQuantity: Number(i.billed_quantity),
            quantity: Number(i.quantity),
            unitCost: Number(i.unitCost),
          });
        }
      } else {
        let item = {
          id: !!i.vendor_credit_item_id ? String(i.vendor_credit_item_id) : undefined,
          billItemId: String(i.bill_item_id),
          code: String(i.item_code),
          description: String(i.description),
          billedQuantity: Number(i.billed_quantity),
          quantity: Number(i.quantity),
          unitCost: Number(i.unitCost),
          taxes: i.taxes.map(
            ta => ({
              id: !!ta.tax_amount_id ? String(ta.tax_amount_id) : undefined,
              taxId: String(ta.taxId),
              label: String(ta.label),
              percent: Number(ta.percent),
            })
          )
        };
        group.push(item);
      }
      return {
        ...o,
        [i.purchase_order_id ?? i.order_id]: group
      };
    },
    {}
  ) ?? {};

  const vendorCredit = {
    id: !!data.vendor_credit_id ? String(data.vendor_credit_id) : undefined,
    type: !!data.bill ? "BILL" : "SUPPLIER",
    number: !!data.form_number ? Number(data.form_number) : undefined,
    dateIssued: new Date(data.date_issued),
    division: {
      id: String(data.division.division_id),
      name: String(data.division.division_name),
    },
    bill: !!data.bill ? {
      id: String(data.bill.bill_id),
      number: String(data.bill.form_number),
      active: Boolean(data.bill.active)
    } : undefined,
    currency: String(data.currency_id),
    total: !data.bill ? Number(data.total_subtotal) : undefined,
    notes: String(data.notes),
    dateExported: !!data.date_exported ? new Date(data.date_exported) : undefined,
    qboVendorCreditRef: !!data.qbo_vendor_credit_ref ? Number(data.qbo_vendor_credit_ref) : undefined,
    sourceForms: !!data.bill ? Object.values(data.purchase_orders).map(
      (po: any) => ({
        id: String(po.purchase_order_id),
        type: "PURCHASE-ORDER",
        number: String(po.form_number),
        project: projectsByOrderId[po.order_id],
        items: items[String(po.purchase_order_id)] ?? []
      })
    ).concat(
      data.orders.map(
        (o: any) => ({
          id: String(o.order_id),
          type: "SALES-ORDER",
          number: String(o.form_number),
          project: projectsByOrderId[o.order_id],
          items: items[String(o.order_id)]
        })
      ).filter(o => o.items.length > 0)
    ) : undefined
  } as VendorCredit;
  return vendorCredit;
}

const createVendorCredit = (vendorCredit: VendorCredit) => async (dispatch) => {
  dispatch(actions.loading());
  let data;
  if ("SUPPLIER" === vendorCredit.type) {
    data = {
      division_id: vendorCredit.division.id,
      date_issued: vendorCredit.dateIssued.toLocaleDateString("en-CA"), // i.e. YYYY-mm-dd
      notes: vendorCredit.notes,
      total_subtotal: vendorCredit.total,
    };
    if (vendorCredit?.tax_id) {
      data.tax_id = vendorCredit.tax_id;
    }
  } else {
    data = {
      bill_id: vendorCredit.bill.id,
      date_issued: vendorCredit.dateIssued.toLocaleDateString("en-CA"), // i.e. YYYY-mm-dd
      notes: vendorCredit.notes,
      items: vendorCredit.sourceForms.reduce(
        (forms: Array<any>, form) => forms.concat(
          form.items.reduce(
            (items: Array<any>, item) => items.concat(
              ("breakdowns" in item) ?
                item.breakdowns.map(
                  b => ({
                    bill_item_id: b.billItemId,
                    quantity: b.quantity
                  })
                ).concat(
                  item.costs.map(
                    c => ({
                      bill_item_id: c.billItemId,
                      quantity: c.quantity
                    })
                  )
                ) : {
                  bill_item_id: item.billItemId,
                  quantity: item.quantity
                }
            ),
            [] as Array<any>
          )
        ),
        [] as Array<any>
      )
    };
  }
  
  const result = await oauth('POST', 'vendor-credit', data);
  const vendor_credit = adaptVendorCredit(result.json.vendor_credit);
  dispatch(actions.created(vendor_credit));
  return vendor_credit;
};

const updateVendorCredit = (vendorCredit: VendorCredit) => async (dispatch) => {
  dispatch(actions.loading());
  const data = "SUPPLIER" === vendorCredit.type ?
    {
      division_id: vendorCredit.division.id,
      notes: vendorCredit.notes,
      total_subtotal: vendorCredit.total,
    } : {
      notes: vendorCredit.notes,
      items: vendorCredit.sourceForms.reduce(
        (forms: Array<any>, form) => forms.concat(
          form.items.reduce(
            (items: Array<any>, item) => items.concat(
              ("breakdowns" in item) ?
                item.breakdowns.map(
                  b => ({
                    vendor_credit_item_id: b.id,
                    quantity: b.quantity
                  })
                ).concat(
                  item.costs.map(
                    c => ({
                      vendor_credit_item_id: c.id,
                      quantity: c.quantity
                    })
                  )
                ) : {
                  vendor_credit_item_id: item.id,
                  quantity: item.quantity
                }
            ),
            [] as Array<any>
          )
        ),
        [] as Array<any>
      )
    };
  const result = await oauth('PUT', `vendor-credit/${vendorCredit.id}`, data);
  const vendor_credit = adaptVendorCredit(result.json.vendor_credit);
  dispatch(actions.updated(vendor_credit));
  return vendor_credit;
};

export const retrieveVendorCredit = (id: string) => async (dispatch) => {
  dispatch(actions.loading());
  const result = await oauth('GET', `vendor-credit/${id}`);
  const vendor_credit = adaptVendorCredit(result.json.vendor_credit);
  dispatch(actions.retrieved(vendor_credit));
  return vendor_credit;
};

export const deleteVendorCredit = (id: string) => async (dispatch) => {
  dispatch(actions.loading());
  const result = await oauth('DELETE', `vendor-credit/${id}`);
  dispatch(actions.deleted(id));
};

export const deleteMultipleVendorCredits = (ids: string[]) => async (dispatch) => {
  dispatch(actions.loading());
  const result = await oauth('DELETE', 'vendor-credit', { ids });
  ids.forEach(id => dispatch(actions.deleted(id)));
};

export const saveVendorCredit = (data: VendorCredit) => {
  if (data.id) {
    return updateVendorCredit(data);
  } else {
    return createVendorCredit(data);
  }
};

export function getDefaultSupplierVendorCredit(tax_id: string = ''): SupplierVendorCredit {
  let vendor_credit: SupplierVendorCredit = {
    type: "SUPPLIER",
    dateIssued: new Date(),
    division: {
      id: "",
      name: "",
    },
    currency: "USD",
    total: 0,
    notes: "",
  };
  if (tax_id) {
    vendor_credit.tax_id = tax_id;
  }
  return vendor_credit;
}

export async function getDefaultBillVendorCredit(bill_id: string): Promise<BillVendorCredit> {
  const result = await oauth('GET', 'vendor-credit', { actionName: 'prepare-vendor-credit', bill_id });
  const vendor_credit = adaptVendorCredit(result.json.vendor_credit);
  if (vendor_credit.type !== "BILL") {
    throw new Error("Invalid vendor credit");
  }
  return vendor_credit;
}

export const selectors = adapter.getSelectors();

export default slice.reducer;
