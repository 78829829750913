import React from "react";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
const ProjectNameColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> =
{
  header: "Project Name",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_name", "job_number"],
  accessorKey: "job_name",
  canSort: true,
  orderByField: "job_name",
  width: 50,
  textOptions: {
    truncate: true,
  },
  dataTransform: ({ job_name, job_number }) => ({
    href: `/project/${job_number}`,
    text: job_name,
  }),
};

const ProjectNumberColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> =
{
  header: "Project #",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["form_number", "job_number"],
  accessorKey: "form_number",
  canSort: true,
  orderByField: "form_number",
  width: 50,
  textOptions: {
    truncate: true,
  },
  dataTransform: ({ form_number, job_number }) => ({
    href: `/project/${job_number}`,
    text: form_number,
  }),
};

const TotalColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Sales Order Total",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_subtotal"],
  accessorKey: "total_subtotal",
  canSort: true,
  orderByField: "total_subtotal",
  width: 100,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ total_subtotal }) => ({
    amount: total_subtotal,
    currency_id: 'USD',
  }),
};

const DateColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Date> = {
  header: "Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_orderdate"],
  accessorKey: "date_orderdate",
  canSort: true,
  orderByField: "date_orderdate",
  width: 50,
};

const OrderRepColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Avatar> = {
  header: "Order Rep",
  type: BASE_COLUMN_TYPES.Avatar,
  fields: [
    "sales_rep_id",
    "sales_rep_first_name",
    "sales_rep_last_name",
    "sales_rep_avatar_file_id",
  ],
  accessorKey: "sales_rep_id",
  canSort: true,
  orderByField: "sales_rep_id",
  width: 50,
  dataTransform: ({
    sales_rep_first_name,
    sales_rep_last_name,
    sales_rep_avatar_file_id,
  }) => ({
    full_name: `${sales_rep_first_name} ${sales_rep_last_name}`,
    avatar_file_id: sales_rep_avatar_file_id,
  }),
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ProjectNameColumn,
  ProjectNumberColumn,
  TotalColumn,
  OrderRepColumn,
  DateColumn,
];
