import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const ClientComparativeReportConfig: ReportConfigBase = {
  type: EReportTypes.ClientComparative,
  endpoint: "report/client-comparative",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "Allows you to view invoiced dollars by client within any time range and compare them to another time range. The results will be presented as both a percentage and dollar value change for the time frame you set.",
    url: "https://help.commonsku.com/knowledge/comparative-report",
  },
};

export default ClientComparativeReportConfig;
