import React from "react";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { Avatar } from "@commonsku/styles";
import { EReportTypes } from "../../routes/report_types";
import { AvatarInitials } from "../../../helpers/Avatar";

const ProductColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Generic
> = {
  header: "Product",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["product_id", "product_name", "default_img_id", "default_img_file_path"],
  accessorKey: "product",
  canSort: true,
  orderByField: "product_name",
  width: 136,
  dataTransform: ({ product_id, product_name, default_img_file_path }) => ({
    cell: <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
      <img width={30} src={default_img_file_path.startsWith('http') || default_img_file_path.startsWith('/') ? default_img_file_path : '/' + default_img_file_path} alt={product_name} />
      <a style={{ display: "-webkit-box", overflow: 'hidden', textOverflow: 'ellipsis', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, whiteSpace: 'normal' }}
        target="_blank" href={`/product.php?id=${product_id}`} rel="noreferrer">{product_name}</a>
    </div>,
    tooltipText: product_name,
  }),
};

const SupplierColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Link> = {
  header: "Supplier",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["division_name", "company_id", "division_id"],
  accessorKey: "division_name",
  canSort: true,
  orderByField: "division_name",
  width: 100,
  dataTransform: ({ division_name, company_id, division_id }) => ({
    text: `${division_name}`,
    href: `/vendor.php?id=${company_id}&division_id=${division_id}`,
    tooltipText: division_name,
  }),
  textOptions: {
    truncate: true,
  },
};

const SkuColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "SKU",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["product_supplier_code"],
  accessorKey: "product_supplier_code",
  canSort: true,
  orderByField: "product_supplier_code",
  width: 100,
};

const ItemsSoldColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Quantity> = {
  header: "Items Sold",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["total_sold"],
  accessorKey: "total_sold",
  canSort: true,
  orderByField: "total_sold",
  width: 30,
  dataTransform: ({ total_sold }) => ({
    amount: parseInt(total_sold).toFixed(0),
  }),
  subReportType: EReportTypes.ProductsSoldProject,
};

const TotalRevColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Total",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_rev"],
  accessorKey: "total_rev",
  canSort: true,
  orderByField: "total_rev",
  width: 100,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ total_rev }) => ({
    amount: total_rev,
    currency_id: 'USD',
  }),
  subReportType: EReportTypes.ProductsSoldProject,
};

const TotalProfitColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Money> = {
  header: "Profit",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_profit"],
  accessorKey: "total_profit",
  canSort: true,
  orderByField: "total_profit",
  width: 100,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ total_profit }) => ({
    amount: total_profit,
    currency_id: 'USD',
  }),
  subReportType: EReportTypes.ProductsSoldProject,
};

const TotalProjectsColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Quantity> = {
  header: "Projects",
  type: BASE_COLUMN_TYPES.Quantity,
  fields: ["order_count", "order_ids"],
  accessorKey: "order_count",
  canSort: true,
  orderByField: "order_count",
  width: 50,
  dataTransform: ({ order_count }) => ({
    amount: order_count,
  }),
  subReportType: EReportTypes.ProductsSoldProject,
};

const RepsColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Generic> = {
  header: "Reps",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["reps"],
  accessorKey: "reps",
  canSort: true,
  orderByField: "sales_rep_ids",
  width: 150,
  dataTransform: ({ reps }) => ({
    cell: <div style={{ paddingLeft: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {reps && reps.length > 0 && reps.slice(0, 3).map((rep, i) => {
        if (rep.user_image_paths?.small) {
          return <Avatar shape="circle" key={i} ml={-10} pic={rep.user_image_paths.small} />;
        } else {
          return <AvatarInitials key={i} name={rep.user_first_name + ' ' + rep.user_last_name} style={{ width: '32px', height: '32px', marginLeft: '-10px' }} />;
        }
      })}
    </div>,
    tooltipText: reps.map((rep, i) => { return <div>{rep.user_first_name + ' ' + rep.user_last_name}</div>; }),
  }),
  subReportType: EReportTypes.ProductsSoldProject,
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ProductColumn,
  SupplierColumn,
  SkuColumn,
  ItemsSoldColumn,
  TotalRevColumn,
  TotalProfitColumn,
  TotalProjectsColumn,
  RepsColumn,
];
