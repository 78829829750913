import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";
import { projects } from "./sub-reports";

const ProductsSoldReportConfig: ReportConfigBase = {
  type: EReportTypes.ProductsSold,
  endpoint: "report/products-sold",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "Products sold",
    url: "https://help.commonsku.com/knowledge/productsoldreport",
  },
  subReports: {
    configs: [projects],
    titleTemplate: (rowData: Record<string, any>) => {
      return `Projects for ${rowData.product.product_name}`;
    }
  },
};

export default ProductsSoldReportConfig;
