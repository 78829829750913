import React, { useCallback } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { AsyncSelect, LabeledAsyncSelect } from "@commonsku/styles";

import { getSupplierAccountDropdown } from "../selectors/dropdowns";
import { createFinalizeLoadSupplierAccountList } from "../actions/supplier";
import { oauth } from "../utils";

const getDivisionOptions = createSelector(
  getSupplierAccountDropdown,
  options => options.map(o => ({ ...o, value: o.division_id, label: o.division_name })),
);

interface Option {
  division_id: string;
  division_name: string;
  default_currency_id: string;
  value: string;
  label: string;
}

function AsyncDivisionSelect(
  {
    autoFocus = false,
    defaultMenuIsOpen = false,
    isDisabled = false,
    label,
    labelStyle,
    onChange,
    openMenuOnFocus = false,
    placeholder = "Select Division...",
    value,
  } : {
    autoFocus?: boolean;
    defaultMenuIsOpen?: boolean;
    isDisabled?: boolean;
    label?: string;
    labelStyle?: React.CSSProperties;
    onChange?: (value: Option) => void;
    openMenuOnFocus?: boolean;
    placeholder?: string;
    value?: string;
  }
) {
  const dispatch = useDispatch();
  const options = useSelector(getDivisionOptions);
  const selected = options.find(o => o.value === value);

  const handleChange = useCallback(
    (e?: Option) => {
      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  const loadDivisions = useCallback(
    (searchTerm: string, callback: (options: Option[]) => void) => {
      const search = debounce(
        async () => {
          if (searchTerm.length < 2) {
            return [];
          }
          const { json: { companies } } = await oauth(
            "GET",
            "company",
           {
             company_type: "SUPPLIER",
             accounts_only: true,
             search: true,
             company_name: searchTerm.toLowerCase().trim(),
             "max-results": 20,
           }
          );
          const options = companies.map(
            sa => ({
              ...sa,
              value: sa.division_id,
              label: sa.division_name,
            })
          );
          callback(options);
          dispatch(createFinalizeLoadSupplierAccountList(companies));
        }
      );
      search();
    },
    []
  );

  if (!!label) {
    return (
      <LabeledAsyncSelect
        autoFocus={autoFocus}
        cacheOptions
        defaultMenuIsOpen={defaultMenuIsOpen}
        defaultOptions={options}
        inPopup
        isDisabled={isDisabled}
        label={label}
        labelStyle={labelStyle}
        loadOptions={loadDivisions}
        onChange={handleChange}
        openMenuOnFocus={openMenuOnFocus}
        value={selected}
      />
    );
  } else {
    return (
      <AsyncSelect
        autoFocus={autoFocus}
        cacheOptions
        defaultMenuIsOpen={defaultMenuIsOpen}
        defaultOptions={options}
        inPopup
        isDisabled={isDisabled}
        loadOptions={loadDivisions}
        onChange={handleChange}
        openMenuOnFocus={openMenuOnFocus}
        value={selected}
      />
    );
  }
}

export default AsyncDivisionSelect;
