import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Button, ConfirmPopup } from "@commonsku/styles";
import { useDispatch } from "react-redux";
import { useIdentity, useHasCapabilities } from "../../../../hooks";
import { deleteMultipleBills } from "../../../../redux/bills";
import { useReportContext } from "../../report_context";
import { oauth } from "../../../../utils";
import ExportToAccountingPopup, {
  EntityType,
  AccountingSoftware,
  exportToAccounting,
  getAccountingSoftwareName,
  AccountingExportReport
} from "../../../finance/ExportToAccountingPopup";

export function prepareSelectBillsLabel<
  T extends Record<string, unknown> = Record<string, unknown>
>(selected: T[]): React.ReactNode {
  if (selected.length === 0) {
    return "Select one or more bills";
  }
  if (selected.length === 1) {
    return "1 bill selected";
  }
  return `${selected.length} bills selected`;
}

export function DeleteBillsButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing?: boolean) => void,
    disabled?: boolean,
  }
) {
  const canDelete = useHasCapabilities(["DELETE-BILL"]);
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();
  const { fetchReport } = useReportContext();

  const bill_ids = useMemo(
    () => selected.map((row: any) => row.bill_reference_number.bill_id),
    [selected]
  );

  const handleDelete = useCallback(async () => {
    setExecuting(true);
    try {
      await dispatch(deleteMultipleBills(bill_ids));
      fetchReport();
    } catch (e) {
      toast.error("Unable to delete bills");
    } finally {
      setExecuting(false);
      setShowConfirm(false);
    }
  }, [setExecuting, bill_ids]);

  if (!canDelete) {
    return null;
  }

  return (
    <Button
      disabled={disabled}
      onClick={() => setShowConfirm(true)}
      variant="error"
    >
      {showConfirm && (
        <ConfirmPopup
          confirmText="Are you sure you want to delete?  Unpaid commissions will be recalculated."
          onClose={() => setShowConfirm(false)}
          onDelete={handleDelete}
        />
      )}
      Delete
    </Button>
  );
}

export function createExportToAccountingButton(
  entityType: EntityType,
  getRowId: (row: any) => string,
) {
  function ExportToAccountingButton<T extends Record<string, unknown> = Record<string, unknown>>(
    {
      selected,
      setExecuting,
      disabled,
    }: {
      selected: T[],
      setExecuting: (executing?: boolean) => void,
      disabled?: boolean,
    }
  ) {
    const { fetchReport } = useReportContext();
    const [accountingExportReport, setAccountingExportReport] = useState<AccountingExportReport | null | false>(false);
    const identity = useIdentity();
    const accountingSoftware = (identity.accounting_software ?? "CSV") as AccountingSoftware;
    const label = `Export to ${getAccountingSoftwareName(accountingSoftware)}`;
  
    const ids = useMemo(
      () => selected.map(getRowId),
      [selected]
    );
  
    const handleClick = useCallback(async () => {
      setExecuting(true);
      try {
        setAccountingExportReport(null);
        const report = await exportToAccounting(entityType, accountingSoftware, ids);
        if (report) {
          setAccountingExportReport(report);
        } else {
          setExecuting(false);
          setAccountingExportReport(false);
          fetchReport();
        }
      } catch (e) {
        toast.error("Unable to export");
        setAccountingExportReport(false);
        setExecuting(false);
      }
    }, [accountingSoftware, fetchReport, setExecuting, ids]);
  
    const handleClose = useCallback(() => {
      setAccountingExportReport(false);
      setExecuting(false);
      fetchReport();
    }, [fetchReport, setExecuting]);
  
    return (
      <Button
        disabled={disabled}
        onClick={handleClick}
      >
        {false !== accountingExportReport && <ExportToAccountingPopup
          entityType={entityType}
          ids={ids}
          onClose={handleClose}
          report={accountingExportReport}
        />}
        {label}
      </Button>
    );
  }
  return ExportToAccountingButton;
}
