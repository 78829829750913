import React from "react";
import { dateRangePickerFields } from "../../filters/components/DateRangePicker";
import { useReportContext } from "../../report_context";
import { Status } from "../../../../constants";
import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";

const stageOptions = [
  { label: "Open", value: "OPEN" },
  { label: "In Production", value: Status.SALES_ORDER_IN_PRODUCTION },
  { label: "Shipped", value: Status.SALES_ORDER_SHIPPED },
  { label: "Ready To Be Invoiced", value: Status.SALES_ORDER_READY_TO_BE_INVOICED },
];

function DateFilterLabel() {
  const { query } = useReportContext();
  switch ("status_id" in query ? query.status_id : null) {
    case Status.SALES_ORDER_IN_PRODUCTION:
      return "In-Hands Date";
    case Status.SALES_ORDER_SHIPPED:
      return "Date Shipped";
    default:
      return "Order Date";
  }
}

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Stage",
    type: FILTER_TYPES.GenericSelect,
    options: stageOptions,
    fields: "status_id",
    defaultValue: "OPEN",
  },
  {
    label: <DateFilterLabel />,
    type: FILTER_TYPES.DateRange,
    fields: ["category_reportdate", "start_reportdate", "end_reportdate"],
    defaultValue: ["this_year", "", ""],
  },
  {
    label: "Client",
    type: FILTER_TYPES.Client,
    fields: "client_id",
  },
  {
    label: "Order Rep",
    type: FILTER_TYPES.User,
    fields: ["sales_rep_id", "sales_rep_team_ids", "include_inactive_sales_reps"],
  },
  {
    label: "Project",
    fields: "project_search",
    type: FILTER_TYPES.Query,
    fetchReportOnEnterPress: true,
  },
];
