import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { createAction } from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";
import {
  VendorCredit,
  SupplierVendorCredit,
  BillVendorCredit,
  SourceForm,
  PurchaseOrderItem,
  ExtraItem,
  Breakdown,
  Cost,
} from "../../types/vendor_credit";

import { saveVendorCredit } from "../../redux/vendor_credits";

type DraftBy<T, K extends keyof T> = Omit<T, K> & { [key in K]: string };

export type DraftSupplierVendorCredit = DraftBy<SupplierVendorCredit, "total">;
export type DraftExtraItem = DraftBy<ExtraItem, "quantity">;
export type DraftBreakdown = DraftBy<Breakdown, "quantity">;
export type DraftCost = DraftBy<Cost, "quantity">;
export type DraftPurchaseOrderItem = Omit<
  PurchaseOrderItem,
  "breakdowns" | "costs"
> & {
  breakdowns: DraftBreakdown[];
  costs: DraftCost[];
};
type DraftItem = DraftExtraItem | DraftPurchaseOrderItem;
type DraftSourceForm = Omit<SourceForm, "items"> & {
  items: DraftItem[];
};
export type DraftBillVendorCredit = Omit<BillVendorCredit, "sourceForms"> & {
  sourceForms: DraftSourceForm[];
};

type DraftVendorCredit = DraftSupplierVendorCredit | DraftBillVendorCredit;

function toDraft(vendorCredit: VendorCredit): DraftVendorCredit {
  if (vendorCredit.type === "SUPPLIER") {
    return {
      ...vendorCredit,
      total: vendorCredit.total.toFixed(2),
    };
  } else if (vendorCredit.type === "BILL") {
    return {
      ...vendorCredit,
      sourceForms: vendorCredit.sourceForms.map((form) => ({
        ...form,
        items: form.items.map((item) => {
          if ("breakdowns" in item) {
            return {
              ...item,
              breakdowns: item.breakdowns.map((b) => ({
                ...b,
                quantity: b.quantity.toFixed(0),
              })),
              costs: item.costs.map((c) => ({
                ...c,
                quantity: c.quantity.toFixed(0),
              })),
            };
          } else {
            return {
              ...item,
              quantity: item.quantity.toFixed(0),
            };
          }
        }),
      })),
    };
  }
}

function fromDraft(draft: DraftVendorCredit): VendorCredit {
  if (draft.type === "SUPPLIER") {
    return {
      ...draft,
      total: Number(draft.total),
    };
  } else if (draft.type === "BILL") {
    return {
      ...draft,
      sourceForms: draft.sourceForms.map((form: DraftSourceForm) => ({
        ...form,
        items: form.items.map((item) => {
          if ("breakdowns" in item) {
            return {
              ...item,
              breakdowns: item.breakdowns.map((b) => ({
                ...b,
                quantity: Number(b.quantity),
              })),
              costs: item.costs.map((c) => ({
                ...c,
                quantity: Number(c.quantity),
              })),
            };
          } else {
            return {
              ...item,
              quantity: Number(item.quantity),
            };
          }
        }),
      })),
    };
  }
}

function isValidTotal(value: string): boolean {
  const total = Number(value);
  return (
    value !== "" && !Number.isNaN(total) && Number.isFinite(total) && total >= 0
  );
}
function isValidQuantity(value: string): boolean {
  const quantity = Number(value);
  return value !== "" && Number.isInteger(quantity) && quantity >= 0;
}

function isValidDraft(draft: DraftVendorCredit): boolean {
  if (draft.type === "SUPPLIER") {
    return draft.division.id !== "" && isValidTotal(draft.total);
  } else if (draft.type === "BILL") {
    return (
      draft.bill.id !== "" &&
      draft.sourceForms.every((form) =>
        form.items.every((item) =>
          !("breakdowns" in item)
            ? isValidQuantity(item.quantity)
            : item.breakdowns.every((b) => isValidQuantity(b.quantity)) &&
              item.costs.every((c) => isValidQuantity(c.quantity)),
        ),
      )
    );
  }
}

interface Division {
  division_id: string;
  division_name: string;
  default_currency_id: string;
}

interface ItemQuantityPayload {
  billItemId: string;
  quantity: string;
}

const setNotes = createAction<string>("vendor-credit/set-notes");
const setTotal = createAction<string>("vendor-credit/set-total");
const setDivision = createAction<Division>("vendor-credit/set-division");
const setItemQuantity = createAction<ItemQuantityPayload>(
  "vendor-credit/set-item-quantity",
);
const resetDraft = createAction<VendorCredit>("vendor-credit/reset-draft");
const setTaxId = createAction<string>("vendor-credit/set-tax");

function draftReducer(
  state: DraftVendorCredit,
  action: Action,
): DraftVendorCredit {
  if (setNotes.match(action)) {
    return { ...state, notes: action.payload };
  } else if (setTotal.match(action)) {
    if ("BILL" === state.type) {
      return state;
    }
    return { ...state, total: action.payload };
  } else if (setDivision.match(action)) {
    if ("BILL" === state.type) {
      return state;
    }
    return {
      ...state,
      currency: action.payload.default_currency_id ?? "USD",
      division: {
        id: action.payload.division_id,
        name: action.payload.division_name,
      },
    };
  } else if (setItemQuantity.match(action)) {
    if ("SUPPLIER" === state.type) {
      return state;
    }
    return {
      ...state,
      sourceForms: state.sourceForms?.map((form) => ({
        ...form,
        items: form.items.map((item) =>
          "breakdowns" in item
            ? {
                ...item,
                breakdowns: item.breakdowns.map((b) =>
                  b.billItemId === action.payload.billItemId
                    ? {
                        ...b,
                        quantity: action.payload.quantity,
                      }
                    : b,
                ),
                costs: item.costs.map((c) =>
                  c.billItemId === action.payload.billItemId
                    ? {
                        ...c,
                        quantity: action.payload.quantity,
                      }
                    : c,
                ),
              }
            : item.billItemId === action.payload.billItemId
              ? {
                  ...item,
                  quantity: action.payload.quantity,
                }
              : item,
        ),
      })),
    };
  } else if (resetDraft.match(action)) {
    return toDraft(action.payload);
  } else if (setTaxId.match(action)) {
    if ("BILL" === state.type) {
      return state;
    }
    return { ...state, tax_id: action.payload };
  }
  return state;
}

export default function useDraft(vendorCredit: VendorCredit) {
  const dispatch = useDispatch();
  const [draft, updateDraft] = useReducer(draftReducer, vendorCredit, toDraft);

  useEffect(() => {
    updateDraft(resetDraft(vendorCredit));
  }, [vendorCredit]);

  const isNew = !vendorCredit.id;
  const isEditable = !vendorCredit.dateExported;
  const isValid = isValidDraft(draft);
  const updater = {
    setNotes: (notes: string) => updateDraft(setNotes(notes)),
    setDivision: (division: Division) => updateDraft(setDivision(division)),
    setTotal: (total: string) => updateDraft(setTotal(total)),
    setItemQuantity: (billItemId: string, quantity: string) =>
      updateDraft(setItemQuantity({ billItemId, quantity })),
    setTaxId: (tax_id: string) => updateDraft(setTaxId(tax_id)),
  };
  const save = async () => {
    if (!isValid) {
      return;
    }
    await dispatch(saveVendorCredit(fromDraft(draft)));
  };

  return {
    draft,
    isNew,
    isEditable,
    isValid,
    updater,
    save,
  };
}
export type DraftUpdater = ReturnType<typeof useDraft>["updater"];
