import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";
import { dateRangePickerFields } from "../../filters/components/DateRangePicker";

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Search",
    fields: "search_query",
    type: FILTER_TYPES.Query,
    fetchReportOnEnterPress: true,
  },
  {
    label: "Dates",
    type: FILTER_TYPES.DateRange,
    fields: dateRangePickerFields,
    defaultValue: ["this_week", "", ""],
  },
  {
    label: "Supplier",
    fields: "supplier_id",
    type: FILTER_TYPES.Supplier,
  },
  {
    label: "Order Rep",
    type: FILTER_TYPES.User,
    fields: [
      "sales_rep_id",
      "sales_rep_teams",
      "include_inactive",
    ],
  },
  {
    label: "Client",
    type: FILTER_TYPES.Client,
    fields: "client_id",
  },
  {
    label: "Industry",
    type: FILTER_TYPES.Industry,
    fields: "industry_id",
  },
  {
    label: "Client Tags",
    type: FILTER_TYPES.ClientTag,
    fields: "client_tags",
  },
  {
    label: "Product Tags",
    type: FILTER_TYPES.ProductTag,
    fields: "product_tags",
  },
  {
    label: "Supplier Tag",
    fields: "supplier_tag_id",
    type: FILTER_TYPES.SupplierTag,
  },
];
