import { Filters } from "./filters";
import { Summary } from "./summary";
import { Columns } from "./columns";
import { BulkActions } from "./bulk-actions";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const FinanceReceiveBillsReportConfig: ReportConfigBase = {
  type: EReportTypes.FinanceReceiveBills,
  endpoint: "purchaseorder/receive-bills",
  filtersBase: Filters,
  summaryBase: Summary,
  columnsBase: Columns,
  withSelection: true,
  intro: {
    description:
      "Receive Bills",
    url: "https://help.commonsku.com/knowledge/supplier-billing-reports",
  },
  bulkActions: BulkActions,
};

export default FinanceReceiveBillsReportConfig;
