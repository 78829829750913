import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@commonsku/styles";
import { BulkActionConfigBase } from "../index";
import { useIdentity } from "../../../../hooks";
import { oauth, fetchCsv, createDownload } from "../../../../utils";
import { useHasCapabilities } from "../../../../hooks";
import { useReportContext } from "../../report_context";
import { getDefaultBill } from "../../../../redux/bills";
import { Bill } from "../../../../types/bills";
import BillPopup from "../../../bills/BillPopup";

export function prepareLabel<
  T extends Record<string, unknown> = Record<string, unknown>
>(selected: T[]): React.ReactNode {
  if (selected.length === 0) {
    return "Select one or more purchase orders";
  }
  if (selected.length === 1) {
    return "1 purchase order selected";
  }
  return `${selected.length} purchase orders selected`;
}

export function CreateBillButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing?: boolean) => void,
    disabled?: boolean,
  }
) {
  const [bill, setBill] = useState<Bill | undefined>();
  const { fetchReport } = useReportContext();
  const canCreateBill = useHasCapabilities(["CREATE-BILL"]);

  const purchaseOrderIds = useMemo(
    () => selected.map((row: any) => row.purchase_order_number.purchase_order_id),
    [selected]
  );

  const handleClick = useCallback(async () => {
    setExecuting(true);
    try {
      const bill = await getDefaultBill(purchaseOrderIds);
      setBill(bill);
    } catch (e) {
      toast.error("Unable to create bill from selected POs.");
      setExecuting(false);
    }
  }, [setExecuting, purchaseOrderIds]);

  const onClose = useCallback(
    () => {
      setBill(undefined);
      fetchReport();
      setExecuting(false);
    },
    []
  );

  if (!canCreateBill) {
    return null;
  }

  const divisionIds = new Set(selected.map((row: any) => row.division_name.division_id));
 
  return (
    <>
      {bill && <BillPopup bill={bill} onClose={onClose} />}
      <Button
        disabled={disabled || divisionIds.size !== 1}
        onClick={handleClick}
      >
        Create Bill
      </Button>
    </>
  );
}

export function MarkAsBilledButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing?: boolean) => void,
    disabled?: boolean,
  }
) {
  const { fetchReport } = useReportContext();
  const canModifyPurchaseOrder = useHasCapabilities(["MODIFY-PURCHASE-ORDER"]);

  const purchaseOrderIds = useMemo(
    () => selected.map((row: any) => row.purchase_order_number.purchase_order_id),
    [selected]
  );

  const handleClick = useCallback(async () => {
    setExecuting(true);
    try {
      await Promise.all(
        purchaseOrderIds.map(
          async (poId) =>  await oauth("PUT", `purchaseorder/${poId}`, { billed: true })
        )
      );
      toast.success("POs marked as billed");
      fetchReport();
    } catch (e) {
      toast.error("Unable to mark POs billed");
    } finally {
      setExecuting(false);
    }
  }, [setExecuting, fetchReport, purchaseOrderIds]);

  if (!canModifyPurchaseOrder) {
    return null;
  }

  return (
    <Button
      disabled={disabled}
      onClick={handleClick}
    >
      Mark as Billed
    </Button>
  );
}

export function ExportToCsvButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing?: boolean) => void,
    disabled?: boolean,
  }
) {
  const canExport = useHasCapabilities(["EXPORT-REPORT"]);

  const purchaseOrderIds = useMemo(
    () => selected.map((row: any) => row.purchase_order_number.purchase_order_id),
    [selected]
  );

  const handleClick = useCallback(async () => {
    setExecuting(true);
    try {
      const rows = await Promise.all(
        purchaseOrderIds.map(
          poId => fetchCsv(
            `purchaseorder/${poId}?quickbooks=1`
          )
        )
      );
      const headers = (await rows[0].text()).split("\n").shift() + "\n";
      const csv = headers + rows.map(
        async (row) => (await row.text()).split("\n").filter(Boolean).slice(1).join("\n")
      ).join("\n");
      await createDownload(
        "data:text/plain;charset=utf-8," + encodeURIComponent(csv),
        "purchase-orders.csv"
      );
    } catch (e) {
      toast.error("Unable to export purchase orders to CSV");
    } finally {
      setExecuting(false);
    }
  }, [setExecuting, purchaseOrderIds]);

  if (!canExport) {
    return null;
  }

  return (
    <Button
      disabled={disabled}
      onClick={handleClick}
    >
      Export to CSV
    </Button>
  );
}


export const BulkActions: BulkActionConfigBase = {
  prepareLabel,
  actions: [
    CreateBillButton,
    MarkAsBilledButton,
    ExportToCsvButton,
  ]
};
