import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";

const ProductsSoldProjectsReportConfig: ReportConfigBase = {
  type: EReportTypes.ProductsSoldProject,
  endpoint: "report?report_type=products-sold-projects",
  filtersBase: [],
  summaryBase: [],
  usePost: true,
  columnsBase: Columns,
  withSelection: false,
  intro: {
    description:
      "Details of projects",
    url: "https://help.commonsku.com/knowledge/products-sold",
  },
};

export default ProductsSoldProjectsReportConfig;
