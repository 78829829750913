import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { colors, EllipsisIcon, fontFamilies } from "@commonsku/styles"
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { ClientColumn, SupplierColumn, PurchaseOrderNumberColumn } from "../common";
import DropdownMenu, { MenuTrigger } from "../../../DropdownMenu";
import { useReportContext } from "../../report_context";
import { oauth } from "../../../../utils";
import { Bill } from "../../../../types/bills";
import { getDefaultBill } from "../../../../redux/bills";
import BillPopup from "../../../bills/BillPopup";

const ProjectNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Project #",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number"],
  accessorKey: "job_number",
  canSort: true,
  orderByField: "job_number",
  width: 83,
  dataTransform: ({ job_number }) => ({
    href: `/project/${job_number}`,
    text: `#${job_number}`,
  }),
};

export const DateShippedColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Date Shipped",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_shipped"],
  accessorKey: "date_shipped",
  canSort: true,
  orderByField: "date_shipped",
  width: 120,
};

export const UnbilledAmountColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Unbilled Amount",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["unbilled_amount", "currency_id"],
  accessorKey: "unbilled_amount",
  canSort: true,
  orderByField: "unbilled_amount",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ unbilled_amount, currency_id }) => ({
    amount: unbilled_amount,
    currency_id,
  }),
};

const StyledDropdownMenu = styled(DropdownMenu)`
& > .dropdown-menu-trigger {
  color: ${colors.primary1.main};
  border-radius: 5px;
  padding: 4px 4px 0 4px;
}
&.dropdown-menu-open > .dropdown-menu-trigger {
  border: 2px solid ${colors.primary1.main};
}
&&& > ul {
  border: 2px solid ${colors.primary1.main};
  border-radius: 5px;
  margin-top: 4px;
  right: 1rem;
  min-width: 200px;

  & > li {
    font-family: ${fontFamilies.regular};
    color: ${colors.neutrals.bodyText};
  }
}
`;

function PurchaseOrderActions({ purchase_order_id }: { purchase_order_id: string }) {
  const [bill, setBill] = useState<Bill | undefined>();
  const { fetchReport } = useReportContext();

  const options = useMemo(
    () => [
      {
        value: "Create Bill",
        hideOnClick: true,
        onClick: async () => {
          const bill = await getDefaultBill([purchase_order_id]);
          setBill(bill);
        }
      },
      {
        value: "Mark as Billed",
        hideOnClick: true,
        onClick: async () => {
          try {
            await oauth("PUT", `purchaseorder/${purchase_order_id}`, { billed: true });
            toast.error("Marked as billed");
            fetchReport();
          } catch (e) {
            toast.error("Unable to mark PO as billed");
          }
        }
      }
    ],
    [purchase_order_id]
  );
  const onClose = useCallback(
    () => setBill(undefined),
    []
  );

  return (
    <>
      {bill && <BillPopup bill={bill} onClose={onClose} />}
      <StyledDropdownMenu options={options} align="right">
       <MenuTrigger><EllipsisIcon altText="Actions" /></MenuTrigger>
      </StyledDropdownMenu>
    </>
  );
}

const ActionColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Generic> =
{
  header: "Actions",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["purchase_order_id"],
  accessorKey: "purchase_order_id",
  canSort: false,
  width: 120,
  justify: "center",
  dataTransform: ({ purchase_order_id }) => ({
    cell: <PurchaseOrderActions purchase_order_id={purchase_order_id} />
  })
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  PurchaseOrderNumberColumn,
  SupplierColumn,
  ClientColumn,
  UnbilledAmountColumn,
  DateShippedColumn,
  ProjectNumberColumn,
  ActionColumn,
];
